@import "/opt/atlassian/pipelines/agent/build/src/webroot/frontend/src/Resources.less";
/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

@filesColorBorder: @colorBorder;
@filesColorHighlight: @colorGreen;
@filesColorHighlightHover: @colorButtonSecondary;

body {
  
  [data-file-upload] {
	margin-top: 15px;
	
	[data-file-upload-drop] {
	  padding: 20px 0;
	  width: 100%;
	  text-align: center;
	  border: 1px solid @filesColorHighlight;
	  color: @filesColorHighlight;
	  
	  .ti {
		font-size: 1.5em;
		margin-right: 10px;
		vertical-align: middle;
	  }
	  
	  .btn {
		margin-left: 10px;
	  }
	  
	  &.dragover {
		color: @colorBodyBg;
		background: @filesColorHighlight;
		
		> * {
		  visibility: hidden;
		  
		  &.ti,
		  &.progressBar {
			visibility: visible;
		  }
		}
	  }
	  
	  &.uploading {
		label {
		  display: none;
		}
	  }
	  
	  .progressBar {
		position: relative;
		width: 50%;
		margin: auto;
		text-align: center;
		border: 1px solid @colorBodyBg;
		background: @colorBodyBg;
		padding: 10px 0;
		
		.progressBarInner {
		  position: absolute;
		  top: 0;
		  left: 0;
		  width: 0;
		  height: 100%;
		  background: @filesColorHighlight;
		}
	  }
	  
	  [data-file-upload-choose] {
		display: none;
	  }
	}
  }
  
  .imageBox {
	[data-file-editname-display] {
	  display: none;
	}
	
	[data-file-controls] {
	  position: absolute;
	  padding: 5px;
	  right: 0;
	  top: 0;
	  background: @colorBodyBg;
	}
  }
}