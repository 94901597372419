/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:26
 */
/* ----------------------------------------------------------
	Body
-----------------------------------------------------------*/
body {
  font-family: @fontFamilySans;
  color: @colorCopy;
  background: @colorBodyBg;
  font-size: @bodyFontSize;
  line-height: @bodyLineHeight;
  font-weight: @fontWeight-reg;
  
  overflow-y: scroll; //force scrollbar for short pages
}

.showOnlyOn-phone {
  display: none;
}

/* ----------------------------------------------------------
	Typography
-----------------------------------------------------------*/
a {
  text-decoration: none;
  color: @colorLink;
  
  &:hover {
	color: @colorLinkHover;
  }
}


ul,
ol {
  padding: 0;
  margin: 0 0 10px 25px;
}

.hl-special {
  .hl-special();
}

.hl-1 {
  .hl-1();
}

.hl-2 {
  .hl-2();
}

.hl-3 {
  .hl-3();
}

.hl-label {
  .hl-label();
}

//default headlines in content
.sidebar,
.textBox {
  h2:not(.hl-1):not(.hl-2):not(.hl-3):not(.hl-special) {
	.hl-1();
  }
}


.hl-1() {
  font-size: 22px;
  font-weight: @fontWeight-reg;
  line-height: 1.3;
  margin: 40px 0 14px;
  
  &:first-child {
	margin-top: 0;
  }
}

.hl-2() {
  font-size: 18px;
  font-weight: @fontWeight-reg;
  line-height: 1.3;
  margin: 40px 0 16px;
  
  &:first-child {
	margin-top: 0;
  }
}

.hl-3() {
  font-size: 14px;
  font-weight: @fontWeight-bold;
  line-height: 1.3;
  margin: 40px 0 16px;
  
  &:first-child {
	margin-top: 0;
  }
}

.hl-label() {
  font-size: @bodyFontSize;
  font-weight: @fontWeight-reg;
  margin: 5px 0 20px;
  color: @colorCopyMid;
}

.hl-special() {
  font-size: 32px;
  font-weight: @fontWeight-light;
  line-height: 1.3;
  margin: 40px 0 20px;
  
  &:first-child {
	margin-top: 0;
  }
}

.pageContents p:first-child {
  margin-top: 0;
}


/* ----------------------------------------------------------
	buttons
-----------------------------------------------------------*/
a.btn,
.btn,
input.btn,
button.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: @bodyFontSize;
  text-decoration: none;
  transition: 0.3s all;
  text-align: center;
  color: @colorButton;
  border: 1px solid;
  cursor: pointer;
  height: auto;
  
  &:hover,
  &.btn-icon.act {
	text-decoration: none;
	color: #FFF;
	background: @colorButton;
	border-color: @colorButton;
  }
  
  &.btn-icon {
	padding: 0;
	width: 30px;
	height: 30px;
	line-height: 1;
	padding-top: 6px; //depends on icon-set used
  }
  
  &.btn-hero {
	background: @colorButton;
	color: #FFF !important;
	border-color: @colorButton;
	
	&:hover {
	  background: @colorButtonHover;
	}
  }
  
}

.controls-switchGroup,
.controls-formControls,
.bar-filter {
  
  .btn,
  a.btn {
	color: @colorButtonSecondary;
	padding: 5px 10px; //smaller than standard
	width: 120px; //all same width
	
	&:hover {
	  color: #FFF;
	}
	
	&:not(.btn-hero):hover {
	  border-color: @colorButtonSecondary;
	  background: @colorButtonSecondary;
	}
	
	&.act {
	  color: @colorButton;
	  
	  &:hover {
		color: #FFF !important;
		background: @colorButton;
		border-color: @colorButton;
	  }
	}
  }
}

.controls-formControls {
  border-top: 1px solid @colorBorder;
  margin-top: 20px;
  padding-top: 20px;
  
  .btn,
  a.btn {
	margin-right: 10px;
	
	&:last-child {
	  margin-right: 0;
	}
  }
}

a.iconSolo,
.iconSolo {
  display: inline-block;
}

.controls-cmsControls {
  text-align: right;
}

.link-learnMore,
a.link-learnMore {
  display: block;
  color: @colorBlue;
  
  &::after {
	.themifyIcon("");
	margin-left: 10px;
	font-size: 0.7em;
  }
}

.link-download,
a.link-download {
  &::after {
	.themifyIcon("");
	margin-left: 10px;
	font-size: 0.7em;
  }
}

/* ----------------------------------------------------------
	forms
-----------------------------------------------------------*/
input,
select,
textarea,
button {
  border: 1px solid @colorInputBorder;
  background: @colorInputBg;
  font-family: @fontFamilySans;
  color: @colorInput;
  font-size: @bodyFontSize;
  line-height: @bodyLineHeight;
  padding: 5px 10px;
  vertical-align: middle;
  transition: 0.3s all;
  
  //for iOS devices:
  //-webkit-appearance: none; //beware: selects will render without any arrow indicator
  border-radius: 0;
  
  &[type="range"] {
	border: 0;
  }
  
  &:focus {
	background: @colorInputBgHover;
	outline: none; //for Chrome
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  width: 350px;
}


input,
select {
  height: 30px;
  
  &[multiple] {
	height: 200px;
  }
}

optgroup,
option {
  background: @colorInputBg;
  font-family: @fontFamilySans;
  color: @colorInput;
  font-size: @bodyFontSize;
  line-height: @bodyLineHeight;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button {
  //border: 0;
  //color: @colorButton;
  //background: @colorButtonBg;
  
  &[disabled] {
	opacity: 0.7;
	cursor: not-allowed;
  }
}

/* ----------------------------------------------------------
	cake inputs
-----------------------------------------------------------*/
.input {
  margin-bottom: 15px;
  
  label {
	display: inline-block;
	min-width: 150px;
  }
  
  .inputHelp {
	display: inline-block;
	padding-left: 150px;
	font-size: 13px;
	margin-top: 5px;
	color: #a1c167;
  }
  
  &.rte {
	[data-content-editable] {
	  margin-top: 10px;
	  padding-top: 5px;
	  border-top: 1px solid @colorAddContentsBorder;
	}
  }
  
  &.files {
	[data-file-upload-inner-save-handling-table] {
	  margin-top: 10px;
	  padding-top: 5px;
	  border-top: 1px solid @colorAddContentsBorder;
	  
	  [data-file-upload-inner-save-handling-template] {
		display: none;
	  }
	}
  }
  
  &.error {
	input, select {
	  border-color: #f00;
	}
  }
}

.error-message {
  color: #f00;
}

#JobAddEditForm {
  .error-message {
	padding-left: 50px;
  }
  
  .error-message:before {
	content: '!';
	border: 2px solid #f00;
	border-radius: 50px;
	color: #f00;
	font-size: 26px;
	line-height: 26px;
	text-align: center;
	padding: 7px;
	position: absolute;
	width: 40px;
	height: 40px;
	display: block;
	left: 10px;
  }
}

.submit {
  display: inline-block;
  vertical-align: top;
}


/* ----------------------------------------------------------
	Header
-----------------------------------------------------------*/
.block-header {
  background: @colorHeaderBg;
  .positionNonStatic(fixed);
  height: @headerHeight;
  z-index: 100;
  border-bottom: 1px solid @colorBorder;
  
  .pageLogo {
	display: inline-block;
	margin-top: 20px;
	height: @headerHeight - 40px;
	
	a {
	  display: block;
	  height: 100%;
	  
	  img {
		display: block;
		height: 100%;
	  }
	}
  }
  
  .menuIcon {
	display: none; //default
  }
  
  .loggedBox {
	position: absolute;
	right: 0;
	top: 17px;
	padding-right: @paddingHeaderRight;
	padding-top: 15px;
	text-transform: uppercase;
	color: @colorCopyMid;
	font-size: @fontSizeSmall;
	
	a {
	  color: @colorCopyLight;
	}
	
	.loggedUserBox {
	  display: inline-block;
	  
	  img {
		display: inline-block;
		width: 45px;
		height: 45px;
		border-radius: 50px;
		background: #DDD;
		margin-right: 10px;
		vertical-align: middle;
	  }
	  
	  .userInfo {
		display: inline-block;
		height: 20px;
		position: relative;
		
		.userInfo-name {
		  font-size: 18px;
		  font-weight: @fontWeight-bold;
		  text-transform: none;
		}
		
		.userInfo-role {
		  position: absolute;
		  bottom: -25px;
		  left: 0;
		}
	  }
	}
	
	.metaNavi {
	  display: inline-block;
	  height: 20px;
	  
	  .nav-separator {
		display: inline-block;
		margin: 0 10px;
	  }
	  
	  .moreBox {
		display: inline-block;
	  }
	  
	}
	
	
  }
}

/* ----------------------------------------------------------
	content
-----------------------------------------------------------*/
.block-content {
  padding-top: @headerHeight;
  position: relative;
  min-height: ~"calc(100vh - 78px)"; //78px = footer height. set minheight to have the footer on bottom on short pages
}


/* ----------------------------------------------------------
	breadcrump
-----------------------------------------------------------*/
.breadcrump {
  font-size: 14px;
  
  a,
  span {
	margin-right: 10px;
	text-decoration: none;
	
	&::before {
	  .themifyIcon("");
	  display: inline-block;
	  margin-right: 7px;
	  font-size: 0.7em;
	}
  }
}

/* ----------------------------------------------------------
	pageheader
-----------------------------------------------------------*/
.pageHeader {
  height: 190px; //big enough for 2 lines of subnavigation
  padding-top: 40px;
  position: relative;
  
  a {
	color: @colorNavigation;
	
	&.btn {
	  color: @colorButton;
	  
	  &:not(.btn-hero):hover {
		color: #FFF;
		background: @colorButton;
		border-color: @colorButton;
	  }
	}
	
	&:hover {
	  color: @colorNavigationHover;
	}
  }
  
  .hl-1 {
	.btn {
	  position: relative;
	  top: -7px;
	}
  }
  
  .pageSubNavigation {
	font-size: 19px;
	position: absolute;
	bottom: 4px;
	left: 0;
	
	a {
	  text-decoration: none;
	  display: inline-block;
	  margin-right: 15px;
	  font-weight: @fontWeight-light;
	}
	
	.controls-addPage {
	  display: inline-block;
	  color: @colorControlsAddPage;
	  
	  a {
		color: @colorControlsAddPage;
		
		&:hover {
		  color: @colorControlsAddPageHover;
		}
	  }
	}
	
	.controls-movePage {
	  font-size: 0.7em;
	}
  }
  
  .hl-1 {
	font-size: 38px;
	font-weight: @fontWeight-light;
	margin: 0 0 20px;
	
	.iconSolo {
	  transform: scaleX(-1); //Todo: use correct icon
	  font-size: 0.7em;
	  margin-right: 20px; //for UI controls
	}
  }
  
  
}

/* ----------------------------------------------------------
	pagecontents
-----------------------------------------------------------*/
.pageContents {
  border-top: 1px solid @colorBorder;
  padding-bottom: 80px;
  padding-top: 15px;
  
  .hl-3 {
	font-size: @bodyFontSize;
	font-weight: @fontWeight-bold;
  }
  
  p {
	max-width: 820px; //TODO: 8 spalten
  }
  
  .contenttools_elem {
	.clearfix();
	border: solid 1px #dddddd;
	padding: 15px 0;
	
	> *:first-child {
	  //margin-top:1em;
	}
	
	
	.ce-element--type-text {
	  cursor: text;
	  padding: 0 5px;
	}
	
	image,
	[data-ce-tag=img],
	img,
	video,
	.ce-element--type-video,
	iframe,
	.ce-element--type-image {
	  clear: both;
	  display: block;
	  max-width: 100%;
	  margin-bottom: 5px;
	  margin-left: initial;
	  margin-right: initial;
	  
	  &.ce-element--type-image {
		background-size: contain;
	  }
	  
	  &.align-left {
		clear: initial;
		float: left;
		margin-right: 20px;
	  }
	  
	  &.align-right {
		clear: initial;
		float: right;
		margin-left: 20px;
	  }
	}
	
	.text-center {
	  text-align: center;
	}
	
	.text-left {
	  text-align: left;
	}
	
	.text-right {
	  text-align: right;
	}
  }
  
  .controls-addContent {
	padding: 10px;
	border: 1px dashed @colorAddContentsBorder;
	margin: 30px 0 50px;
	color: @colorAddContents;
	
	.btn {
	  vertical-align: middle;
	}
  }
  
  .sidebar {
	
	.controls-addContent {
	  
	  select {
		width: 230px;
	  }
	}
  }
  
  
  .controls-editContent {
	border-right: 1px dashed @colorAddContentsBorder;
	border-top: 1px dashed @colorAddContentsBorder;
	color: @colorAddContents;
	margin: 60px 0 0;
	padding: 5px;
	text-align: right;
  }
  
  .bar-filter {
	border-top: 1px solid @colorBorder;
	border-bottom: 1px solid @colorBorder;
	margin-bottom: 10px;
	padding: 10px 0;
	
	&:first-child {
	  border-top: none;
	  padding-top: 0;
	}
	
	.filterLabel {
	  margin-bottom: 10px;
	  font-size: @fontSizeSmall;
	}
	
	input[type="text"],
	input[type="email"],
	input[type="password"],
	select,
	textarea {
	  width: 250px;
	  margin-right: 10px;
	  margin-bottom: 10px;
	  border-color: @colorBlue;
	  border-width: 1px;
	  height: 32px;
	}
	
	textarea {
	  height: auto;
	}
	
	.aboBox {
	  background: #eee;
	  display: inline-block;
	  padding: 11px;
	  margin: 10px 0;
	  
	  .aboBox-label {
		display: inline-block;
		margin-right: 10px;
		font-size: @fontSizeSmall;
	  }
	  
	  .btn,
	  a.btn {
		width: auto;
		color: @colorGreen;
	  }
	}
  }
  
  //standard content tables
  table:not(.table-downloads):not(.table-index) {
	width: 100%;
	border-collapse: collapse;
	margin-bottom: 30px;
	
	tr {
	  
	  th, td {
		border: 1px solid @colorBorder;
		padding: 5px;
		vertical-align: top;
	  }
	  
	  th {
		text-align: left;
		font-weight: @fontWeight-bold;
	  }
	  
	  td {
	  }
	}
  }
  
  table.table-downloads {
	tr {
	  td {
		.heading,
		.subheading {
		  max-width: 500px;
		  word-wrap: break-word;
		}
	  }
	}
  }
  
  table.table-downloads,
  table.table-index {
	@tableSpacingHorizontal: 20px;
	@tableSpacingVerticalInner: 10px;
	@tableSpacingVerticalOuter: 10px;
	
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	
	tr {
	  
	  th, td {
		border-right: @tableSpacingHorizontal solid transparent;
		position: relative;
		
		&:last-child {
		  border-right: 0;
		}
	  }
	  
	  th {
		text-align: left;
		text-transform: uppercase;
		font-weight: @fontWeight-reg;
		position: relative;
		padding-bottom: @tableSpacingVerticalOuter + 4;
		font-size: @fontSizeSmall;
		
		&::after {
		  content: " ";
		  display: block;
		  position: absolute;
		  width: 100%;
		  height: 0;
		  border-bottom: 1px solid @colorBorderLight;
		  bottom: @tableSpacingVerticalOuter;
		}
		
		a {
		  color: @colorTableHeadLink;
		  text-decoration: none;
		  display: block;
		  
		  &:hover {
			color: @colorTableHeadLinkHover;
		  }
		  
		  &::after {
			margin-lft: 5px;
			display: inline-block;
		  }
		  
		  &.desc::after,
		  &.asc::after {
			display: inline-block;
			margin-left: 7px;
		  }
		  
		  &.desc::after {
			.themifyIcon("");
		  }
		  
		  &.asc::after {
			.themifyIcon("");
		  }
		}
	  }
	  
	  td {
		padding-bottom: @tableSpacingVerticalOuter + @tableSpacingVerticalInner + 1;
		
		&::after {
		  content: " ";
		  display: block;
		  position: absolute;
		  width: 100%;
		  height: 0;
		  border-bottom: 1px dashed @colorBorderLight;
		  bottom: @tableSpacingVerticalOuter;
		}
		
		.heading {
		  font-weight: @fontWeight-reg;
		}
		
		.subheading {
		  color: @colorCopySecondary;
		  font-size: @fontSizeMini;
		}
		
		.icon {
		  font-size: 25px;
		  margin-right: 5px;
		}
		
	  }
	  
	  &:last-child {
		td {
		  &::after {
			border-bottom: 1px solid @colorBorderLight;
		  }
		}
	  }
	  
	  .tablecol-actions {
		width: 134px; //big enough for 4 buttons
	  }
	  
	}
	
  }
  
  .thumb-mini {
	display: inline-block;
	width: 30px;
	height: 30px;
	position: relative;
	background: #000;
	
	img {
	  display: block;
	  width: 100%;
	  opacity: 0.5;
	}
	
	span {
	  .positionNonStatic(absolute);
	  color: #FFF;
	  text-align: center;
	  line-height: 30px;
	}
  }
  
  .contentSection {
	margin-bottom: 50px;
	position: relative;
	
	&.contentSection-bordered {
	  border-top: 1px solid @colorBorder;
	  
	  &:first-child {
		border-top: none;
	  }
	}
  }
  
  .teasers-prominent {
	margin-bottom: 40px;
  }
  
  .teaser {
	border: 1px solid @colorBorder;
	margin-bottom: 10px;
	
	.teaser_content {
	  color: @colorTeaser;
	  padding: 15px;
	  
	  .heading {
		font-weight: @fontWeight-reg;
		color: @colorCopy;
	  }
	  
	  .subheading {
	  }
	}
	
	.teaser_footer {
	  color: @colorTeaser;
	}
	
	&.teaser-imagetile,
	&.teaser-listitem {
	  
	  .teaser_img {
		padding-top: 67%; //= 172/256
		background-size: cover;
		background-position: center center;
		background-color: #DDD;
	  }
	  
	  .teaser_content {
		font-size: @fontSizeSmall;
		
		.heading {
		  margin: 0 0 5px;
		  font-size: 17px;
		  line-height: 1.3;
		  color: @colorCopy;
		  height: 2.6em;
		  overflow: hidden;
		}
		
		.subheading {
		  height: 3em;
		  overflow: hidden;
		}
		
		.link-learnMore {
		  font-size: 15px;
		}
	  }
	}
	
	&.teaser-imagetile {
	  
	  .teaser_footer {
		position: relative;
		padding: 0 15px 5px;
		font-size: @fontSizeSmall;
		
		.control-bookmark {
		  position: absolute;
		  right: 10px;
		  bottom: 10px;
		}
	  }
	}
	
	&.teaser-listitem {
	  border: 0;
	  margin-bottom: 50px;
	  
	  .teaser_content {
		padding-top: 0;
	  }
	  
	  .teaser_footer {
		margin-top: 20px;
	  }
	}
	
	&.teaser-subpage {
	  
	  &:hover {
		background: #EEE;
	  }
	  
	  .teaser_content {
		min-height: 110px;
		
		.heading {
		  margin: 0 0 5px;
		  font-size: 15px;
		  word-wrap: break-word;
		}
		
		.subheading {
		  font-size: @fontSizeMini;
		}
	  }
	}
	
	&.teaser-referatteam {
	  margin-left: 1% !important;
	  margin-right: 1% !important;
	  width: 32% !important;
	  
	  .teaser_content {
		min-height: 190px;
		
		.heading {
		  margin: 0 0 5px;
		  font-size: 15px;
		}
		
		.subheading {
		  font-size: @fontSizeMini;
		}
	  }
	  
	  &.gridCol-3 {
		&:nth-child(3n) {
		  margin-right: 0 !important;
		}
		
		&:nth-child(3n+1) {
		  margin-left: 0 !important;
		}
	  }
	}
	
	&.gridCol-3 {
	  &:nth-child(4n) {
		margin-right: 0;
	  }
	  
	  &:nth-child(4n+1) {
		margin-left: 0;
	  }
	}
  }
  
  .btn-tile {
	display: block;
	height: 385px;
	
	span {
	  .verticalAlign();
	}
  }
  
  .searchResultsIntro {
	font-size: 20px;
  }
  
  .search-resultbox {
	border-top: 1px solid @colorBorder;
	margin-top: 50px;
	padding-top: 10px;
	
	h4 {
	  .hl-3();
	  font-weight: @fontWeight-light;
	  margin-bottom: 0;
	}
	
	.search-resultbox-results {
	  
	  p {
	  }
	  
	  .search-resultbox-results-result {
		margin-bottom: 35px;
		background: #eee;
		padding: 20px;
		
		.highlight {
		  background: fade(#FFFF00, 40%);
		}
		
		> .hl-2 {
		  .hl-1();
		  color: @colorBlue;
		  margin: 0 0 10px;
		  
		  a {
			color: @colorBlue;
		  }
		}
		
		> .hl-3 {
		  .hl-3();
		  font-weight: @fontWeight-light;
		  margin: 0;
		  margin-top: 5px;
		}
		
		.search-resultbox-results-result-content {
		  margin-top: 5px;
		  
		  :first-child {
			margin-top: 0;
		  }
		  
		  //style unstyled headlines from search result
		  h1,
		  h2,
		  h3 {
			font-weight: @fontWeight-reg;
			font-size: 20px;
		  }
		  
		  img {
			display: none;
		  }
		  
		  > p {
			&:last-of-type {
			  margin-bottom: 0;
			}
			
			&:first-of-type {
			  margin-top: 0;
			}
		  }
		}
		
		> p {
		  margin: 5px 0 0 0;
		  font-size: 13px;
		  
		  a {
			color: @colorCopyLight;
		  }
		}
	  }
	}
	
	.search-resultbox-more {
	  margin-top: 30px;
	  
	  .resultsInfo {
		font-style: italic;
	  }
	}
  }
  
}

/* ----------------------------------------------------------
	Footer
-----------------------------------------------------------*/
.block-footer {
  background: @colorFooterBg;
  color: #FFF;
  padding: 18px 30px 18px 70px;
  line-height: 40px; //= hieght of social logo
  height: 78px;
  
  a {
	color: @colorFooterLink;
	text-decoration: none;
	
	&:hover {
	  color: @colorFooterLinkHover;
	}
  }
  
  .part-copyright {
	display: inline-block;
	margin-right: 40px;
  }
  
  .part-social {
	display: inline-block;
	
	a.linkSocial {
	  border: 1px solid;
	  border-radius: 50px;
	  display: inline-block;
	  text-align: center;
	  height: 40px;
	  width: 40px;
	  line-height: 1;
	  padding-top: 9px;
	  vertical-align: middle;
	  margin: 0 5px;
	  font-size: 20px;
	}
  }
  
  .part-metanav {
	position: absolute;
	right: 30px;
	display: inline-block; //wichtig!
	
	a {
	  margin-left: 10px;
	}
	
  }
}

/* ----------------------------------------------------------
	contact
-----------------------------------------------------------*/

.contact {
  margin-bottom: 20px;
  
  .contact_name {
	font-weight: @fontWeight-bold;
  }
  
  .contact_position {
	color: @colorCopySecondary;
  }
  
  .contact_data {
	margin-top: 20px;
	
	.contact_data_line {
	  .phoneLabel {
		font-weight: @fontWeight-bold;
	  }
	}
  }
}

/* ----------------------------------------------------------
	block sidebar
-----------------------------------------------------------*/
.block-sidebar {
  .positionNonStatic(fixed);
  height: 100%;
  z-index: 200;
  color: @colorBlockSidebar;
  background: @colorBlockSidebarBg;
  width: 40px;
  transition: 0.3s width;
  
  a {
	color: @colorBlockSidebar;
	font-weight: @fontWeight-bold;
	
	&:hover {
	  color: @colorBlockSidebarHover;
	}
  }
  
  .btn,
  a.btn {
	&:hover {
	  background: none;
	  border-color: @colorBlockSidebarHover;
	  color: @colorBlockSidebarHover;
	}
  }
  
  
  &.exp {
	width: 400px;
	max-width: 100%;
	
	.contentCollapsed {
	  display: none;
	}
	
	.contentExpanded {
	  display: block;
	  height: 100%;
	  overflow-y: auto;
	}
  }
  
  .loggedBox {
	display: none;
  }
  
  .contentExpanded {
	display: none; //default
	padding: 30px 40px;
  }
  
  .menuIcon {
	text-align: center;
	font-size: 30px;
	cursor: pointer;
	margin-top: 100px;
  }
  
  input,
  .btn {
	width: 100%;
	display: block;
  }
  
  ul,
  ol {
	margin: 0;
	padding: 0;
	
	li {
	  margin-bottom: 5px;
	  
	  a {
		padding: 5px 0;
		display: block;
	  }
	  
	  ul {
		margin-left: 20px;
		//display: none; //temp
	  }
	}
  }
  
  .sidebar_search {
	margin-bottom: 40px;
	
	input {
	  height: 40px;
	}
  }
  
  .sidebar_hotlink {
	margin-bottom: 20px;
  }
  
  .sidebar_nav {
	margin-bottom: 30px;
  }
  
  .togglerNav_entry {
	position: relative;
	
	.togglerNav_toggler {
	  position: absolute;
	  left: 20px;
	  left: -20px;
	  top: 7px;
	  cursor: pointer;
	  
	  &::after {
		.themifyIcon(""); //=chevron-right
	  }
	}
	
	&.exp {
	  
	  .togglerNav_toggler {
		
		&::after {
		  .themifyIcon(""); //=chevron-down
		}
	  }
	}
	
	.g8_toggleable_toggledContents {
	  display: none; //default
	}
  }
  
}

.sidebarCover {
  .positionNonStatic(fixed);
  height: 100%;
  background: fade(#000, 70%);
  z-index: 199;
}


/* ----------------------------------------------------------
	debugging & dev
-----------------------------------------------------------*/
//todo: remove debug styles
.cake-sql-log {
  margin: 10px 100px 20px;
  color: #999;
}

/* ----------------------------------------------------------
	downloadBox,
	imageBox,
	tilesBox,
	contactBox
-----------------------------------------------------------*/
.downloadBox,
.imageBox,
.tilesBox {
  .hl-1,
  .hl-2,
  .hl-3,
  h1, h2, h3, h4 {
	.hl-label();
  }
}

.imageBox {
  .image {
	height: 150px;
	background-size: cover;
	margin-bottom: 10px;
	cursor: pointer;
	position: relative;
  }
}

.imageBox,
.tilesBox {
  .gridCol-2 {
	&:nth-child(4n) {
	  margin-right: 0;
	}
	
	&:nth-child(4n+1) {
	  margin-left: 0;
	}
  }
}

.contactBox {
  select,
  input {
	width: 100%;
  }
  
  .hl-2 {
	font-size: 16px;
	
	small {
	  line-height: 1.3;
	  display: inline-block;
	  font-size: 12px;
	  margin-top: 5px;
	}
  }
}


/* ----------------------------------------------------------
	controls
-----------------------------------------------------------*/
.controls-pageViewMode {
  
  background: @colorBodyBg;
  padding: 20px;
  position: fixed;
  right: 0;
  top: @headerHeight;
  z-index: 100;
  width: 300px;
  
  .controlsLabel {
	font-size: @fontSizeMini;
	margin-bottom: 5px;
	color: @colorCopyMid;
  }
}

/* ----------------------------------------------------------
	noContentsAvailable
-----------------------------------------------------------*/
.noContentsAvailable {
  background: @colorInfowellBg;
  font-style: italic;
  padding: 5px;
}


/* ----------------------------------------------------------
	back to overview
-----------------------------------------------------------*/
.box-backToOverview {
  border-top: 1px solid @colorBorder;
  font-size: 16px;
  margin-top: 30px;
  padding-top: 10px;
  
  a.link-backToOverview {
	color: @colorCopy;
	
	.ti {
	  color: @colorBlue;
	  display: inline-block;
	  margin-left: 10px;
	}
  }
}

/* ----------------------------------------------------------
	pagination
-----------------------------------------------------------*/
.pagination {
  margin-top: 5px;
  text-align: center;
  
  .current,
  a,
  span > .ti {
	padding: 0 5px;
  }
  
  span > .ti {
	visibility: hidden;
  }
  
  .ti {
	font-size: 0.7em;
  }
}

/* ----------------------------------------------------------
	dashboard
-----------------------------------------------------------*/
.dashboard {
  background: url("../../../img/page-bg.jpg") no-repeat fixed;
  background-size: cover;
  
  .dashboard_row {
	.clearfix();
	margin: 0 auto;
	padding-top: 30px;
	width: 1110px;
	
	.dashboard_col-1 {
	  float: left;
	  width: 630px;
	  margin-right: 20px;
	}
	
	.dashboard_col-2 {
	  float: left;
	  width: 460px;
	}
  }
  
  .flake {
	background: @colorBodyBg;
	position: relative;
	padding: 30px 60px 50px 90px;
	margin-bottom: 20px;
	
	&.flake-news {
	  
	  .flake_items {
		
		&::before {
		  content: " ";
		  border-left: 1px solid @colorBorder;
		  height: 100%;
		  left: 0;
		  position: absolute;
		  left: -30px;
		}
	  }
	}
	
	.flake_items {
	  position: relative;
	  margin-top: 25px;
	}
	
	.flake_moreItems {
	  margin-top: 30px;
	}
	
	.flake_icon {
	  position: absolute;
	  left: 30px;
	  top: 40px;
	  font-size: 30px;
	  color: @colorBlue;
	}
	
	.hl-flake {
	  font-size: 38px;
	  font-weight: @fontWeight-light;
	  margin: 0;
	  line-height: 1.2;
	}
	
	a.item-list,
	.item-list {
	  display: block;
	  border-bottom: 1px solid @colorBorder;
	  display: block;
	  margin-bottom: 10px;
	  padding-bottom: 10px;
	  position: relative;
	  
	  .item-title {
		color: @colorCopy;
		font-weight: @fontWeight-bold;
		display: block;
	  }
	  
	  .item-subtitle {
		color: @colorBlue;
		display: block;
	  }
	  
	  .item-controls {
		position: absolute;
		right: 0;
		top: 0;
	  }
	  
	}
	
	.item-news {
	  margin-bottom: 25px;
	  
	  &:last-child {
		margin-bottom: 0;
	  }
	  
	  .date {
		left: -50px;
		position: relative;
		color: @colorCopySecondary;
		background: #FFF;
	  }
	  
	  .heading {
		font-weight: @fontWeight-reg;
		margin: 3px 0 10px;
		font-size: 17px;
	  }
	  
	  p {
		margin: 0;
	  }
	}
	
	.item-job {
	  border-bottom: 1px solid @colorBorder;
	  display: block;
	  margin-bottom: 20px;
	  padding-bottom: 25px;
	  
	  .heading {
		font-weight: @fontWeight-bold;
		margin: 0 0 0;
		font-size: 17px;
	  }
	  
	  .date {
		color: @colorCopySecondary;
	  }
	}
  }
}


