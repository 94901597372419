/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:27
 */

.clearfix() {
  //same styles like clearfix:
  
  *zoom: 1;
  
  &:before,
  &:after {
	display: table;
	content: "";
	line-height: 0;
  }
  
  &:after {
	clear: both;
  }
}

.positionNonStatic(@position) {
  position: @position;
  left: 0;
  top: 0;
  width: 100%;
}

.themifyIcon(@content) {
  content: @content;
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

.verticalAlign() {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}