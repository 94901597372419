/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */

/* ----------------------------------------------------------
	breakpoint x
-----------------------------------------------------------*/
.body.responsive-small {
}

/* ----------------------------------------------------------
	breakpoint x
-----------------------------------------------------------*/
@media (max-width: 1290px) {
  
  .responsive-big {
  }
  
}


/* ----------------------------------------------------------
	breakpoint desktop narrow, close to phone
-----------------------------------------------------------*/
@media (max-width: 1080px) AND (min-width: @breakpointMinDesktopSmall) {
  .wrapper {
	margin-left: 45px; //for sidebar
  }
}

/* ----------------------------------------------------------
	breakpoint desktop too small for dashboard
-----------------------------------------------------------*/
@media (max-width: 1220px) AND (min-width: 1171px) {
  .dashboard .dashboard_row {
	margin-left: 50px;
  }
}

@media (max-width: 1170px) AND (min-width: @breakpointMinDesktopSmall) {
  .dashboard .dashboard_row {
	margin-left: 50px;
	
	.dashboard_col-1 {
	  width: 530px;
	}
	
	.dashboard_col-2 {
	  width: 410px;
	}
	
  }
}


/* ----------------------------------------------------------
	breakpoint phone
-----------------------------------------------------------*/
@media (max-width: @breakpointMinDesktopSmall - 1) {
  
  /* ----------------------------------------------------------
	  general
  -----------------------------------------------------------*/
  .hideOn-phone {
	display: none;
  }
  
  .showOnlyOn-phone {
	display: block;
  }
  
  
  /* ----------------------------------------------------------
	  sidebar
  -----------------------------------------------------------*/
  .block-sidebar {
	display: none;
	
	&.exp {
	  display: block;
	}
	
	.loggedBox {
	  display: block;
	  border-top: 1px solid @colorCopyLight;
	  padding-top: 10px;
	  margin: 100px 0 25px;
	  
	  .userInfo-name {
		font-weight: @fontWeight-bold;
		text-transform: none;
	  }
	  
	  .userInfo-role {
		font-size: 0.8em;
		text-transform: uppercase;
	  }
	  
	  .btn {
		padding: 5px;
		margin-top: 10px;
	  }
	}
	
  }
  
  
  /* ----------------------------------------------------------
	  header
  -----------------------------------------------------------*/
  .block-header {
	height: 60px;
	
	.pageLogo {
	  margin-top: 5px;
	  height: 50px;
	  position: absolute;
	  right: 10px;
	  
	  img {
		height: 50px;
	  }
	}
	
	.loggedBox {
	  display: none;
	}
	
	.menuIcon {
	  display: inline-block;
	  cursor: pointer;
	  color: @colorCopyMid;
	  font-size: 30px;
	  margin-right: 20px;
	  padding-top: 13px;
	  vertical-align: top;
	}
	
  }
  
  
  /* ----------------------------------------------------------
	  footer
  -----------------------------------------------------------*/
  .block-footer {
	height: auto;
	
	.part-copyright,
	.part-social,
	.part-metanav {
	  display: block;
	  margin: 0 0 20px;
	  text-align: center;
	  position: static;
	}
  }
  
  /* ----------------------------------------------------------
	  content
  -----------------------------------------------------------*/
  .block-content {
	padding-top: 70px;
	min-height: 0;
  }
  
  .controls-pageViewMode {
	position: static;
	width: auto;
	text-align: right;
	
	.controlsLabel {
	  display: none;
	}
	
	a.btn,
	.btn {
	  width: 49%;
	}
  }
  
  .controls-cmsControls {
	margin-bottom: 15px;
	text-align: left;
  }
  
  .pageHeader {
	height: auto;
	padding-top: 0;
	
	.hl-1 {
	  word-wrap: break-word;
	  font-size: 28px;
	}
	
	.pageSubNavigation {
	  position: static;
	  margin-bottom: 20px;
	  
	  a {
		display: block;
		line-height: 1.2;
		margin-bottom: 7px;
		margin-right: 0;
	  }
	}
  }
  
  .pageContents {
	padding-bottom: 50px;
	
	p:last-child {
	  margin-bottom: 0;
	}
	
	.contenttools_elem img {
	  height: auto;
	}
	
	.contentSection {
	  
	  &:last-child {
		margin-bottom: 0;
	  }
	}
	
	.sidebar {
	  padding-top: 40px;
	}
	
	table.table-downloads,
	table.table-index {
	  
	  tr th,
	  tr td {
		border-right: 0;
		
		.heading {
		  word-wrap: break-word;
		}
		
		.subheading {
		  word-break: break-all;
		}
	  }
	}
	
	.bar-filter {
	  
	  input[type="text"],
	  input[type="email"],
	  input[type="password"],
	  select,
	  textarea {
		width: 100%;
	  }
	  
	  .submit {
		display: block;
	  }
	  
	  .btn,
	  a.btn {
		width: 100%;
	  }
	  
	  .aboBox {
		display: block;
		margin-top: 10px;
		
		.btn,
		a.btn {
		  width: 100%;
		  margin-bottom: 5px;
		}
	  }
	}
  }
  
  .tableActions {
	margin-top: 10px;
  }
  
  /* ----------------------------------------------------------
	  dashboard
  -----------------------------------------------------------*/
  .dashboard {
	background: #EEE;
	margin-top: -10px; //to start right under the top navi bar
	
	.dashboard_row {
	  .clearfix();
	  margin: 0 auto;
	  padding: 15px;
	  width: 100%;
	  
	  .dashboard_col-1,
	  .dashboard_col-2 {
		float: none;
		width: auto;
		margin-right: 0;
	  }
	}
	
	.flake {
	  padding: 20px;
	  
	  a.btn:not(.btn-icon),
	  .btn:not(.btn-icon) {
		display: block;
		width: 100%;
		margin-bottom: 5px;
		
		&:last-child {
		  margin-bottom: 0;
		}
	  }
	  
	  .flake_icon {
		font-size: 20px;
		left: 20px;
		top: 24px;
	  }
	  
	  .hl-flake {
		font-size: 25px;
		padding-left: 30px;
	  }
	  
	  .item-news .date {
		position: static;
	  }
	}
	
  }
  
  
  /* ----------------------------------------------------------
	  responsive tables (via JS)
  -----------------------------------------------------------*/
  .tableResponsified {
	border: 1px solid @colorBorder;
	margin-bottom: 20px;
	
	.tableRow {
	  padding: 5px;
	  border-bottom: 1px solid @colorBorder;
	  
	  &:last-child {
		border-bottom: 0;
	  }
	  
	  .tableRow-cell-label {
		display: inline-block;
		margin-right: 10px;
		font-weight: @fontWeight-bold;
	  }
	  
	  .tableRow-cell-val {
		display: inline-block;
	  }
	}
  }
  
  /* ----------------------------------------------------------
	  breadcrump
  -----------------------------------------------------------*/
  .breadcrump {
	margin-bottom: 10px;
  }
  
  /* ----------------------------------------------------------
	  forms
  -----------------------------------------------------------*/
  .inputHelp {
	padding-left: 0 !important;
  }
}
