@import "/opt/atlassian/pipelines/agent/build/src/webroot/frontend/src/Resources.less";
.modal {
	
	padding: 100px 0;
	
	&__outer {
		position: relative;
		min-height: 100%;
	}
	
	&__container {
		width: 100%;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 200;
		overflow: auto;
		
		display: none;
		
		&.modal__container--visible {
			display: block;
		}
	}
	
	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		background: @colorCopy;
		opacity: 0.85;
		width: 100%;
		height: 100%;
	}
	
	&__close {
		position: absolute;
		top: 0;
		right: -50px;
		font-size: 25px;
		line-height: 25px;
		height: 25px;
		width: 25px;
		color: @colorBodyBg;
		
		&:hover {
			cursor: pointer;
			opacity: 0.65;
		}

		@media (max-width: @breakpointMinDesktopSmall - 1) {
			top: -50px;
			right: 0;
		}
	}
	
	&__content {
		position: relative;
		background: @colorBodyBg;
		
		&__inner {
			padding: 40px 25px 20px 25px;
			
			p {
				margin: 2.5em 0;
			}
		}
	}
	
	&__body--visible {
		overflow: hidden;
	}
	
}