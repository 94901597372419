@import "/opt/atlassian/pipelines/agent/build/src/webroot/frontend/src/Resources.less";
/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:24
 */

@contenttoolsColorBorder: @colorBorder;
@contenttoolsColorHighlight: @colorGreen;
@contenttoolsColorHighlightHover: @colorButtonSecondary;

body {
  .ct-app {
	.ct-widget.ct-ignition {
	  display: none !important;
	}
  }
  
  [data-content-editable].ct--highlight {
	animation: none !important;
	outline: none !important;
  }
  
  [data-content-editable] .ce-element--over {
	outline: none !important;
	background: lighten(@contenttoolsColorHighlight, 25%);
	
	&.ce-element--type-image::before {
	  z-index: 2;
	}
	
	&.ce-element--type-image::after {
	  content: '';
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: lighten(@contenttoolsColorHighlight, 25%);
	  z-index: 1;
	  opacity: 0.75;
	}
  }
  
  [data-content-editable] .ce-element--focused {
	outline: none !important;
	background: @contenttoolsColorHighlight;
	color: @colorBodyBg;
	
	a {
	  color: @colorBodyBg;
	}
	
	
	&.ce-element--type-image::before {
	  z-index: 2;
	}
	
	&.ce-element--type-image::after {
	  content: '';
	  display: block;
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: @contenttoolsColorHighlight;
	  z-index: 1;
	  opacity: 0.75;
	}
  }
  
  [data-content-editable] .ce-element--drop:before {
	background-color: @contenttoolsColorHighlight;
  }
  
  [data-content-editable-container],
  [data-content-addable] {
	margin-top: 40px;
	
	&[data-content-addable] {
	  //padding:5px;
	  //border:1px dashed @contenttoolsColorBorder;
	}
	
	[data-content-editable] {
	  //padding-top:5px;
	  //border-bottom:1px solid @contenttoolsColorBorder;
	}
	
	[data-content-editable-controls] {
	  //padding:5px;
	  //border:1px dashed @contenttoolsColorBorder;
	}
  }
  
  .ct-widget {
	
	&.ct-toolbox {
	  background: @colorBodyBg;
	  border: 1px solid @contenttoolsColorBorder;
	  box-shadow: none;
	  z-index: 190; //beneath navi sidebar
	}
	
	&.ct-inspector {
	  background: @colorBodyBg;
	  border-top: 1px solid @contenttoolsColorBorder;
	  border-right: 1px solid @contenttoolsColorBorder;
	  width: 400px;
	  //top:220px;
	  //right:0;
	  left: 0;
	  bottom: 0;
	  
	  .ct-tag {
		background-color: @contenttoolsColorHighlight;
		
		&:after {
		  border-left-color: @contenttoolsColorHighlight;
		}
		
		&:hover {
		  background-color: @contenttoolsColorHighlightHover;
		  
		  &:after {
			border-left-color: @contenttoolsColorHighlightHover;
		  }
		}
	  }
	}
	
	.ct-grip__bump {
	  background: @contenttoolsColorHighlight;
	}
	
	.ct-tool {
	  color: @contenttoolsColorHighlight;
	  border-radius: 0;
	  
	  &:hover {
		background: lighten(@contenttoolsColorHighlight, 25%);
	  }
	  
	  &.ct-tool--applied {
		background: @contenttoolsColorHighlight;
		color: @colorBodyBg;
		box-shadow: none;
	  }
	}
	
	&.ct-anchored-dialog {
	  border-bottom: 2px solid @contenttoolsColorHighlight;
	  
	  &:after {
		border-top-color: @contenttoolsColorHighlight;
	  }
	}
	
	.ct-control--apply,
	.ct-control--insert,
	.ct-control--ok {
	  background: @contenttoolsColorHighlight;
	  
	  &:hover {
		background: @contenttoolsColorHighlightHover;
	  }
	}
	
	.ct-anchored-dialog__button {
	  background: @contenttoolsColorHighlight;
	}
	
	.ct-anchored-dialog__target-button--active:before {
	  color: @contenttoolsColorHighlight;
	}
	
	.ct-ignition__button--confirm {
	  background: @contenttoolsColorHighlight;
	}
	
	.ct-section--applied .ct-section__switch {
	  background-color: @contenttoolsColorHighlight;
	}
	
	.ct-anchored-dialog__button:hover {
	  background: @contenttoolsColorHighlightHover;
	}
	
	.ct-anchored-dialog__target-button--active:hover:before {
	  color: @contenttoolsColorHighlightHover;
	}
	
	.ct-ignition__button--confirm:hover {
	  background: @contenttoolsColorHighlightHover;
	}
  }
}