/* ----------------------------------------------------------
	grid mixin regular
-----------------------------------------------------------*/
.gridStyles(@gridWidth, @gridHalfGutterWidth){

	@gridGutterWidth: @gridHalfGutterWidth * 2; //helper

	/* ----------------------------------------------------------
		includes
	-----------------------------------------------------------*/

	.inc-colStyles(@n){
		width: @n * @gridWidth + ((@n - 1) * @gridGutterWidth);

		&.gridCol-press-left,
		&.gridCol-press {
			margin-left: 0;
			width: @n * @gridWidth + ((@n - 1) * @gridGutterWidth) + @gridHalfGutterWidth;
		}

		&.gridCol-press-right,
		&.gridCol-press {
			margin-right: 0;
			width: @n * @gridWidth + ((@n - 1) * @gridGutterWidth) + @gridHalfGutterWidth;
		}

		&.gridCol-press-left.gridCol-press-right,
		&.gridCol-press {
			width: @n * (@gridWidth + @gridGutterWidth);
		}
	}

	.inc-pushStyles(@n) {
		margin-left:  @gridHalfGutterWidth + (@n * (@gridWidth + @gridGutterWidth)) ;

		&.gridCol-press-left,
		&.gridCol-press {
			margin-left: @n * (@gridWidth + @gridGutterWidth) !important;
		}
	}


	/* ----------------------------------------------------------
		Styles
	-----------------------------------------------------------*/

	.wrapper {
		position: relative;
		margin: 0 auto;
		width: 12 * (@gridGutterWidth + @gridWidth);
	}

	.gridRow {

		.clearfix();

		.gridCol-1,
		.gridCol-2,
		.gridCol-3,
		.gridCol-4,
		.gridCol-5,
		.gridCol-6,
		.gridCol-7,
		.gridCol-8,
		.gridCol-9,
		.gridCol-10,
		.gridCol-11,
		.gridCol-12 {
			float:left;
			margin-left: @gridHalfGutterWidth;
			margin-right: @gridHalfGutterWidth;
			min-height: 1px; //force display
		}

		.gridCol-1 { .inc-colStyles(1); }
		.gridCol-2 { .inc-colStyles(2); }
		.gridCol-3 { .inc-colStyles(3); }
		.gridCol-4 { .inc-colStyles(4); }
		.gridCol-5 { .inc-colStyles(5); }
		.gridCol-6 { .inc-colStyles(6); }
		.gridCol-7 { .inc-colStyles(7); }
		.gridCol-8 { .inc-colStyles(8); }
		.gridCol-9 { .inc-colStyles(9); }
		.gridCol-10 { .inc-colStyles(10); }
		.gridCol-11 { .inc-colStyles(11); }
		.gridCol-12 { .inc-colStyles(12); }


		.gridPush {
			&.gridPush-1 { .inc-pushStyles(1); }
			&.gridPush-2 { .inc-pushStyles(2); }
			&.gridPush-3 { .inc-pushStyles(3); }
			&.gridPush-4 { .inc-pushStyles(4); }
			&.gridPush-5 { .inc-pushStyles(5); }
			&.gridPush-6 { .inc-pushStyles(6); }
			&.gridPush-7 { .inc-pushStyles(7); }
			&.gridPush-8 { .inc-pushStyles(8); }
			&.gridPush-9 { .inc-pushStyles(9); }
			&.gridPush-10 { .inc-pushStyles(10); }
			&.gridPush-11 { .inc-pushStyles(11); }
			&.gridPush-12 { .inc-pushStyles(12); }
		}
	}

	.gridRow * .gridRow {

		:first-child {
			&.gridCol-1,
			&.gridCol-2,
			&.gridCol-3,
			&.gridCol-4,
			&.gridCol-5,
			&.gridCol-6,
			&.gridCol-7,
			&.gridCol-8,
			&.gridCol-9,
			&.gridCol-10,
			&.gridCol-11,
			&.gridCol-12 {
				margin-left: 0;
			}
		}

		:last-child {
			&.gridCol-1,
			&.gridCol-2,
			&.gridCol-3,
			&.gridCol-4,
			&.gridCol-5,
			&.gridCol-6,
			&.gridCol-7,
			&.gridCol-8,
			&.gridCol-9,
			&.gridCol-10,
			&.gridCol-11,
			&.gridCol-12 {
				margin-right: 0;
			}
		}

		.gridPush {
			&.gridPush-1 { .inc-pushStyles(1); }
			&.gridPush-2 { .inc-pushStyles(2); }
			&.gridPush-3 { .inc-pushStyles(3); }
			&.gridPush-4 { .inc-pushStyles(4); }
			&.gridPush-5 { .inc-pushStyles(5); }
			&.gridPush-6 { .inc-pushStyles(6); }
			&.gridPush-7 { .inc-pushStyles(7); }
			&.gridPush-8 { .inc-pushStyles(8); }
			&.gridPush-9 { .inc-pushStyles(9); }
			&.gridPush-10 { .inc-pushStyles(10); }
			&.gridPush-11 { .inc-pushStyles(11); }
			&.gridPush-12 { .inc-pushStyles(12); }
		}
	}

}


/* ----------------------------------------------------------
	grid mixin phone
-----------------------------------------------------------*/
.gridStyles-phone(@mobilePadding){
	.wrapper {
		padding-left: @mobilePadding;
		padding-right: @mobilePadding;
		width: 100%;
	}

	.gridRow {
		width: 100%;

		.gridCol-12,
		.gridCol-11,
		.gridCol-10,
		.gridCol-9,
		.gridCol-8,
		.gridCol-7,
		.gridCol-6,
		.gridCol-5,
		.gridCol-4,
		.gridCol-3,
		.gridCol-2,
		.gridCol-1 {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}

		.gridPush {
			&.gridPush-1,
			&.gridPush-2,
			&.gridPush-3,
			&.gridPush-4,
			&.gridPush-5,
			&.gridPush-6,
			&.gridPush-7,
			&.gridPush-8,
			&.gridPush-9,
			&.gridPush-10,
			&.gridPush-11,
			&.gridPush-12 {
				margin-left: 0;
			}
		}
	}

	.gridRow * .gridRow {

		.gridPush {
			&.gridPush-1,
			&.gridPush-2,
			&.gridPush-3,
			&.gridPush-4,
			&.gridPush-5,
			&.gridPush-6,
			&.gridPush-7,
			&.gridPush-8,
			&.gridPush-9,
			&.gridPush-10,
			&.gridPush-11,
			&.gridPush-12 {
				margin-left: 0;
			}
		}
	}
}




/* ----------------------------------------------------------
	grid definitions
-----------------------------------------------------------*/

@media (min-width: @breakpointMinDesktopLarge) {
	.gridStyles(@min1440-gridWidth, @min1440-gridHalfGutterWidth);
}

@media (max-width: @breakpointMinDesktopLarge - 1) {
	.gridStyles(@max1439-gridWidth, @max1439-gridHalfGutterWidth);
}

@media (max-width: @breakpointMinDesktopSmall - 1) {
	.gridStyles-phone(20px);
}

