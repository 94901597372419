/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:10
 */
/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:23
 */
/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:27
 */
/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:12
 */
/*
* LESS basic reset
* Version 1.0.3
*/
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css);
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}
body {
  margin: 0;
}
a:focus {
  outline: thin dotted;
}
a:hover,
a:active {
  outline: 0;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}
h3 {
  font-size: 1.17em;
  margin: 1em 0;
}
h4 {
  font-size: 1em;
  margin: 1.33em 0;
}
h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}
h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
strong {
  font-weight: bold;
}
blockquote {
  margin: 1em 40px;
}
dfn {
  font-style: italic;
}
mark {
  background: #ff0;
  color: #000;
}
p,
pre {
  margin: 1em 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: '';
  content: none;
}
small {
  font-size: 75%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
dl,
menu,
ol,
ul {
  margin: 1em 0;
}
dd {
  margin: 0 0 0 40px;
}
menu,
ol,
ul {
  padding: 0 0 0 40px;
}
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input {
  line-height: normal;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible;
}
button[disabled],
input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px;
}
input[type="checkbox"] {
  margin-right: 5px;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
a:focus {
  outline: 0;
}
iframe {
  border: 0;
}
/* ----------------------------------------------------------
	grid mixin regular
-----------------------------------------------------------*/
/* ----------------------------------------------------------
	grid mixin phone
-----------------------------------------------------------*/
/* ----------------------------------------------------------
	grid definitions
-----------------------------------------------------------*/
@media (min-width: 1440px) {
  /* ----------------------------------------------------------
		includes
	-----------------------------------------------------------*/
  /* ----------------------------------------------------------
		Styles
	-----------------------------------------------------------*/
  .wrapper {
    position: relative;
    margin: 0 auto;
    width: 1260px;
  }
  .gridRow {
    *zoom: 1;
  }
  .gridRow:before,
  .gridRow:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .gridRow:after {
    clear: both;
  }
  .gridRow .gridCol-1,
  .gridRow .gridCol-2,
  .gridRow .gridCol-3,
  .gridRow .gridCol-4,
  .gridRow .gridCol-5,
  .gridRow .gridCol-6,
  .gridRow .gridCol-7,
  .gridRow .gridCol-8,
  .gridRow .gridCol-9,
  .gridRow .gridCol-10,
  .gridRow .gridCol-11,
  .gridRow .gridCol-12 {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    min-height: 1px;
  }
  .gridRow .gridCol-1 {
    width: 85px;
  }
  .gridRow .gridCol-1.gridCol-press-left,
  .gridRow .gridCol-1.gridCol-press {
    margin-left: 0;
    width: 95px;
  }
  .gridRow .gridCol-1.gridCol-press-right,
  .gridRow .gridCol-1.gridCol-press {
    margin-right: 0;
    width: 95px;
  }
  .gridRow .gridCol-1.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-1.gridCol-press {
    width: 105px;
  }
  .gridRow .gridCol-2 {
    width: 190px;
  }
  .gridRow .gridCol-2.gridCol-press-left,
  .gridRow .gridCol-2.gridCol-press {
    margin-left: 0;
    width: 200px;
  }
  .gridRow .gridCol-2.gridCol-press-right,
  .gridRow .gridCol-2.gridCol-press {
    margin-right: 0;
    width: 200px;
  }
  .gridRow .gridCol-2.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-2.gridCol-press {
    width: 210px;
  }
  .gridRow .gridCol-3 {
    width: 295px;
  }
  .gridRow .gridCol-3.gridCol-press-left,
  .gridRow .gridCol-3.gridCol-press {
    margin-left: 0;
    width: 305px;
  }
  .gridRow .gridCol-3.gridCol-press-right,
  .gridRow .gridCol-3.gridCol-press {
    margin-right: 0;
    width: 305px;
  }
  .gridRow .gridCol-3.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-3.gridCol-press {
    width: 315px;
  }
  .gridRow .gridCol-4 {
    width: 400px;
  }
  .gridRow .gridCol-4.gridCol-press-left,
  .gridRow .gridCol-4.gridCol-press {
    margin-left: 0;
    width: 410px;
  }
  .gridRow .gridCol-4.gridCol-press-right,
  .gridRow .gridCol-4.gridCol-press {
    margin-right: 0;
    width: 410px;
  }
  .gridRow .gridCol-4.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-4.gridCol-press {
    width: 420px;
  }
  .gridRow .gridCol-5 {
    width: 505px;
  }
  .gridRow .gridCol-5.gridCol-press-left,
  .gridRow .gridCol-5.gridCol-press {
    margin-left: 0;
    width: 515px;
  }
  .gridRow .gridCol-5.gridCol-press-right,
  .gridRow .gridCol-5.gridCol-press {
    margin-right: 0;
    width: 515px;
  }
  .gridRow .gridCol-5.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-5.gridCol-press {
    width: 525px;
  }
  .gridRow .gridCol-6 {
    width: 610px;
  }
  .gridRow .gridCol-6.gridCol-press-left,
  .gridRow .gridCol-6.gridCol-press {
    margin-left: 0;
    width: 620px;
  }
  .gridRow .gridCol-6.gridCol-press-right,
  .gridRow .gridCol-6.gridCol-press {
    margin-right: 0;
    width: 620px;
  }
  .gridRow .gridCol-6.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-6.gridCol-press {
    width: 630px;
  }
  .gridRow .gridCol-7 {
    width: 715px;
  }
  .gridRow .gridCol-7.gridCol-press-left,
  .gridRow .gridCol-7.gridCol-press {
    margin-left: 0;
    width: 725px;
  }
  .gridRow .gridCol-7.gridCol-press-right,
  .gridRow .gridCol-7.gridCol-press {
    margin-right: 0;
    width: 725px;
  }
  .gridRow .gridCol-7.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-7.gridCol-press {
    width: 735px;
  }
  .gridRow .gridCol-8 {
    width: 820px;
  }
  .gridRow .gridCol-8.gridCol-press-left,
  .gridRow .gridCol-8.gridCol-press {
    margin-left: 0;
    width: 830px;
  }
  .gridRow .gridCol-8.gridCol-press-right,
  .gridRow .gridCol-8.gridCol-press {
    margin-right: 0;
    width: 830px;
  }
  .gridRow .gridCol-8.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-8.gridCol-press {
    width: 840px;
  }
  .gridRow .gridCol-9 {
    width: 925px;
  }
  .gridRow .gridCol-9.gridCol-press-left,
  .gridRow .gridCol-9.gridCol-press {
    margin-left: 0;
    width: 935px;
  }
  .gridRow .gridCol-9.gridCol-press-right,
  .gridRow .gridCol-9.gridCol-press {
    margin-right: 0;
    width: 935px;
  }
  .gridRow .gridCol-9.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-9.gridCol-press {
    width: 945px;
  }
  .gridRow .gridCol-10 {
    width: 1030px;
  }
  .gridRow .gridCol-10.gridCol-press-left,
  .gridRow .gridCol-10.gridCol-press {
    margin-left: 0;
    width: 1040px;
  }
  .gridRow .gridCol-10.gridCol-press-right,
  .gridRow .gridCol-10.gridCol-press {
    margin-right: 0;
    width: 1040px;
  }
  .gridRow .gridCol-10.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-10.gridCol-press {
    width: 1050px;
  }
  .gridRow .gridCol-11 {
    width: 1135px;
  }
  .gridRow .gridCol-11.gridCol-press-left,
  .gridRow .gridCol-11.gridCol-press {
    margin-left: 0;
    width: 1145px;
  }
  .gridRow .gridCol-11.gridCol-press-right,
  .gridRow .gridCol-11.gridCol-press {
    margin-right: 0;
    width: 1145px;
  }
  .gridRow .gridCol-11.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-11.gridCol-press {
    width: 1155px;
  }
  .gridRow .gridCol-12 {
    width: 1240px;
  }
  .gridRow .gridCol-12.gridCol-press-left,
  .gridRow .gridCol-12.gridCol-press {
    margin-left: 0;
    width: 1250px;
  }
  .gridRow .gridCol-12.gridCol-press-right,
  .gridRow .gridCol-12.gridCol-press {
    margin-right: 0;
    width: 1250px;
  }
  .gridRow .gridCol-12.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-12.gridCol-press {
    width: 1260px;
  }
  .gridRow .gridPush.gridPush-1 {
    margin-left: 115px;
  }
  .gridRow .gridPush.gridPush-1.gridCol-press-left,
  .gridRow .gridPush.gridPush-1.gridCol-press {
    margin-left: 105px !important;
  }
  .gridRow .gridPush.gridPush-2 {
    margin-left: 220px;
  }
  .gridRow .gridPush.gridPush-2.gridCol-press-left,
  .gridRow .gridPush.gridPush-2.gridCol-press {
    margin-left: 210px !important;
  }
  .gridRow .gridPush.gridPush-3 {
    margin-left: 325px;
  }
  .gridRow .gridPush.gridPush-3.gridCol-press-left,
  .gridRow .gridPush.gridPush-3.gridCol-press {
    margin-left: 315px !important;
  }
  .gridRow .gridPush.gridPush-4 {
    margin-left: 430px;
  }
  .gridRow .gridPush.gridPush-4.gridCol-press-left,
  .gridRow .gridPush.gridPush-4.gridCol-press {
    margin-left: 420px !important;
  }
  .gridRow .gridPush.gridPush-5 {
    margin-left: 535px;
  }
  .gridRow .gridPush.gridPush-5.gridCol-press-left,
  .gridRow .gridPush.gridPush-5.gridCol-press {
    margin-left: 525px !important;
  }
  .gridRow .gridPush.gridPush-6 {
    margin-left: 640px;
  }
  .gridRow .gridPush.gridPush-6.gridCol-press-left,
  .gridRow .gridPush.gridPush-6.gridCol-press {
    margin-left: 630px !important;
  }
  .gridRow .gridPush.gridPush-7 {
    margin-left: 745px;
  }
  .gridRow .gridPush.gridPush-7.gridCol-press-left,
  .gridRow .gridPush.gridPush-7.gridCol-press {
    margin-left: 735px !important;
  }
  .gridRow .gridPush.gridPush-8 {
    margin-left: 850px;
  }
  .gridRow .gridPush.gridPush-8.gridCol-press-left,
  .gridRow .gridPush.gridPush-8.gridCol-press {
    margin-left: 840px !important;
  }
  .gridRow .gridPush.gridPush-9 {
    margin-left: 955px;
  }
  .gridRow .gridPush.gridPush-9.gridCol-press-left,
  .gridRow .gridPush.gridPush-9.gridCol-press {
    margin-left: 945px !important;
  }
  .gridRow .gridPush.gridPush-10 {
    margin-left: 1060px;
  }
  .gridRow .gridPush.gridPush-10.gridCol-press-left,
  .gridRow .gridPush.gridPush-10.gridCol-press {
    margin-left: 1050px !important;
  }
  .gridRow .gridPush.gridPush-11 {
    margin-left: 1165px;
  }
  .gridRow .gridPush.gridPush-11.gridCol-press-left,
  .gridRow .gridPush.gridPush-11.gridCol-press {
    margin-left: 1155px !important;
  }
  .gridRow .gridPush.gridPush-12 {
    margin-left: 1270px;
  }
  .gridRow .gridPush.gridPush-12.gridCol-press-left,
  .gridRow .gridPush.gridPush-12.gridCol-press {
    margin-left: 1260px !important;
  }
  .gridRow * .gridRow :first-child.gridCol-1,
  .gridRow * .gridRow :first-child.gridCol-2,
  .gridRow * .gridRow :first-child.gridCol-3,
  .gridRow * .gridRow :first-child.gridCol-4,
  .gridRow * .gridRow :first-child.gridCol-5,
  .gridRow * .gridRow :first-child.gridCol-6,
  .gridRow * .gridRow :first-child.gridCol-7,
  .gridRow * .gridRow :first-child.gridCol-8,
  .gridRow * .gridRow :first-child.gridCol-9,
  .gridRow * .gridRow :first-child.gridCol-10,
  .gridRow * .gridRow :first-child.gridCol-11,
  .gridRow * .gridRow :first-child.gridCol-12 {
    margin-left: 0;
  }
  .gridRow * .gridRow :last-child.gridCol-1,
  .gridRow * .gridRow :last-child.gridCol-2,
  .gridRow * .gridRow :last-child.gridCol-3,
  .gridRow * .gridRow :last-child.gridCol-4,
  .gridRow * .gridRow :last-child.gridCol-5,
  .gridRow * .gridRow :last-child.gridCol-6,
  .gridRow * .gridRow :last-child.gridCol-7,
  .gridRow * .gridRow :last-child.gridCol-8,
  .gridRow * .gridRow :last-child.gridCol-9,
  .gridRow * .gridRow :last-child.gridCol-10,
  .gridRow * .gridRow :last-child.gridCol-11,
  .gridRow * .gridRow :last-child.gridCol-12 {
    margin-right: 0;
  }
  .gridRow * .gridRow .gridPush.gridPush-1 {
    margin-left: 115px;
  }
  .gridRow * .gridRow .gridPush.gridPush-1.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-1.gridCol-press {
    margin-left: 105px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-2 {
    margin-left: 220px;
  }
  .gridRow * .gridRow .gridPush.gridPush-2.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-2.gridCol-press {
    margin-left: 210px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-3 {
    margin-left: 325px;
  }
  .gridRow * .gridRow .gridPush.gridPush-3.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-3.gridCol-press {
    margin-left: 315px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-4 {
    margin-left: 430px;
  }
  .gridRow * .gridRow .gridPush.gridPush-4.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-4.gridCol-press {
    margin-left: 420px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-5 {
    margin-left: 535px;
  }
  .gridRow * .gridRow .gridPush.gridPush-5.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-5.gridCol-press {
    margin-left: 525px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-6 {
    margin-left: 640px;
  }
  .gridRow * .gridRow .gridPush.gridPush-6.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-6.gridCol-press {
    margin-left: 630px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-7 {
    margin-left: 745px;
  }
  .gridRow * .gridRow .gridPush.gridPush-7.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-7.gridCol-press {
    margin-left: 735px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-8 {
    margin-left: 850px;
  }
  .gridRow * .gridRow .gridPush.gridPush-8.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-8.gridCol-press {
    margin-left: 840px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-9 {
    margin-left: 955px;
  }
  .gridRow * .gridRow .gridPush.gridPush-9.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-9.gridCol-press {
    margin-left: 945px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-10 {
    margin-left: 1060px;
  }
  .gridRow * .gridRow .gridPush.gridPush-10.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-10.gridCol-press {
    margin-left: 1050px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-11 {
    margin-left: 1165px;
  }
  .gridRow * .gridRow .gridPush.gridPush-11.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-11.gridCol-press {
    margin-left: 1155px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-12 {
    margin-left: 1270px;
  }
  .gridRow * .gridRow .gridPush.gridPush-12.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-12.gridCol-press {
    margin-left: 1260px !important;
  }
}
@media (max-width: 1439px) {
  /* ----------------------------------------------------------
		includes
	-----------------------------------------------------------*/
  /* ----------------------------------------------------------
		Styles
	-----------------------------------------------------------*/
  .wrapper {
    position: relative;
    margin: 0 auto;
    width: 960px;
  }
  .gridRow {
    *zoom: 1;
  }
  .gridRow:before,
  .gridRow:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .gridRow:after {
    clear: both;
  }
  .gridRow .gridCol-1,
  .gridRow .gridCol-2,
  .gridRow .gridCol-3,
  .gridRow .gridCol-4,
  .gridRow .gridCol-5,
  .gridRow .gridCol-6,
  .gridRow .gridCol-7,
  .gridRow .gridCol-8,
  .gridRow .gridCol-9,
  .gridRow .gridCol-10,
  .gridRow .gridCol-11,
  .gridRow .gridCol-12 {
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    min-height: 1px;
  }
  .gridRow .gridCol-1 {
    width: 60px;
  }
  .gridRow .gridCol-1.gridCol-press-left,
  .gridRow .gridCol-1.gridCol-press {
    margin-left: 0;
    width: 70px;
  }
  .gridRow .gridCol-1.gridCol-press-right,
  .gridRow .gridCol-1.gridCol-press {
    margin-right: 0;
    width: 70px;
  }
  .gridRow .gridCol-1.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-1.gridCol-press {
    width: 80px;
  }
  .gridRow .gridCol-2 {
    width: 140px;
  }
  .gridRow .gridCol-2.gridCol-press-left,
  .gridRow .gridCol-2.gridCol-press {
    margin-left: 0;
    width: 150px;
  }
  .gridRow .gridCol-2.gridCol-press-right,
  .gridRow .gridCol-2.gridCol-press {
    margin-right: 0;
    width: 150px;
  }
  .gridRow .gridCol-2.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-2.gridCol-press {
    width: 160px;
  }
  .gridRow .gridCol-3 {
    width: 220px;
  }
  .gridRow .gridCol-3.gridCol-press-left,
  .gridRow .gridCol-3.gridCol-press {
    margin-left: 0;
    width: 230px;
  }
  .gridRow .gridCol-3.gridCol-press-right,
  .gridRow .gridCol-3.gridCol-press {
    margin-right: 0;
    width: 230px;
  }
  .gridRow .gridCol-3.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-3.gridCol-press {
    width: 240px;
  }
  .gridRow .gridCol-4 {
    width: 300px;
  }
  .gridRow .gridCol-4.gridCol-press-left,
  .gridRow .gridCol-4.gridCol-press {
    margin-left: 0;
    width: 310px;
  }
  .gridRow .gridCol-4.gridCol-press-right,
  .gridRow .gridCol-4.gridCol-press {
    margin-right: 0;
    width: 310px;
  }
  .gridRow .gridCol-4.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-4.gridCol-press {
    width: 320px;
  }
  .gridRow .gridCol-5 {
    width: 380px;
  }
  .gridRow .gridCol-5.gridCol-press-left,
  .gridRow .gridCol-5.gridCol-press {
    margin-left: 0;
    width: 390px;
  }
  .gridRow .gridCol-5.gridCol-press-right,
  .gridRow .gridCol-5.gridCol-press {
    margin-right: 0;
    width: 390px;
  }
  .gridRow .gridCol-5.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-5.gridCol-press {
    width: 400px;
  }
  .gridRow .gridCol-6 {
    width: 460px;
  }
  .gridRow .gridCol-6.gridCol-press-left,
  .gridRow .gridCol-6.gridCol-press {
    margin-left: 0;
    width: 470px;
  }
  .gridRow .gridCol-6.gridCol-press-right,
  .gridRow .gridCol-6.gridCol-press {
    margin-right: 0;
    width: 470px;
  }
  .gridRow .gridCol-6.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-6.gridCol-press {
    width: 480px;
  }
  .gridRow .gridCol-7 {
    width: 540px;
  }
  .gridRow .gridCol-7.gridCol-press-left,
  .gridRow .gridCol-7.gridCol-press {
    margin-left: 0;
    width: 550px;
  }
  .gridRow .gridCol-7.gridCol-press-right,
  .gridRow .gridCol-7.gridCol-press {
    margin-right: 0;
    width: 550px;
  }
  .gridRow .gridCol-7.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-7.gridCol-press {
    width: 560px;
  }
  .gridRow .gridCol-8 {
    width: 620px;
  }
  .gridRow .gridCol-8.gridCol-press-left,
  .gridRow .gridCol-8.gridCol-press {
    margin-left: 0;
    width: 630px;
  }
  .gridRow .gridCol-8.gridCol-press-right,
  .gridRow .gridCol-8.gridCol-press {
    margin-right: 0;
    width: 630px;
  }
  .gridRow .gridCol-8.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-8.gridCol-press {
    width: 640px;
  }
  .gridRow .gridCol-9 {
    width: 700px;
  }
  .gridRow .gridCol-9.gridCol-press-left,
  .gridRow .gridCol-9.gridCol-press {
    margin-left: 0;
    width: 710px;
  }
  .gridRow .gridCol-9.gridCol-press-right,
  .gridRow .gridCol-9.gridCol-press {
    margin-right: 0;
    width: 710px;
  }
  .gridRow .gridCol-9.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-9.gridCol-press {
    width: 720px;
  }
  .gridRow .gridCol-10 {
    width: 780px;
  }
  .gridRow .gridCol-10.gridCol-press-left,
  .gridRow .gridCol-10.gridCol-press {
    margin-left: 0;
    width: 790px;
  }
  .gridRow .gridCol-10.gridCol-press-right,
  .gridRow .gridCol-10.gridCol-press {
    margin-right: 0;
    width: 790px;
  }
  .gridRow .gridCol-10.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-10.gridCol-press {
    width: 800px;
  }
  .gridRow .gridCol-11 {
    width: 860px;
  }
  .gridRow .gridCol-11.gridCol-press-left,
  .gridRow .gridCol-11.gridCol-press {
    margin-left: 0;
    width: 870px;
  }
  .gridRow .gridCol-11.gridCol-press-right,
  .gridRow .gridCol-11.gridCol-press {
    margin-right: 0;
    width: 870px;
  }
  .gridRow .gridCol-11.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-11.gridCol-press {
    width: 880px;
  }
  .gridRow .gridCol-12 {
    width: 940px;
  }
  .gridRow .gridCol-12.gridCol-press-left,
  .gridRow .gridCol-12.gridCol-press {
    margin-left: 0;
    width: 950px;
  }
  .gridRow .gridCol-12.gridCol-press-right,
  .gridRow .gridCol-12.gridCol-press {
    margin-right: 0;
    width: 950px;
  }
  .gridRow .gridCol-12.gridCol-press-left.gridCol-press-right,
  .gridRow .gridCol-12.gridCol-press {
    width: 960px;
  }
  .gridRow .gridPush.gridPush-1 {
    margin-left: 90px;
  }
  .gridRow .gridPush.gridPush-1.gridCol-press-left,
  .gridRow .gridPush.gridPush-1.gridCol-press {
    margin-left: 80px !important;
  }
  .gridRow .gridPush.gridPush-2 {
    margin-left: 170px;
  }
  .gridRow .gridPush.gridPush-2.gridCol-press-left,
  .gridRow .gridPush.gridPush-2.gridCol-press {
    margin-left: 160px !important;
  }
  .gridRow .gridPush.gridPush-3 {
    margin-left: 250px;
  }
  .gridRow .gridPush.gridPush-3.gridCol-press-left,
  .gridRow .gridPush.gridPush-3.gridCol-press {
    margin-left: 240px !important;
  }
  .gridRow .gridPush.gridPush-4 {
    margin-left: 330px;
  }
  .gridRow .gridPush.gridPush-4.gridCol-press-left,
  .gridRow .gridPush.gridPush-4.gridCol-press {
    margin-left: 320px !important;
  }
  .gridRow .gridPush.gridPush-5 {
    margin-left: 410px;
  }
  .gridRow .gridPush.gridPush-5.gridCol-press-left,
  .gridRow .gridPush.gridPush-5.gridCol-press {
    margin-left: 400px !important;
  }
  .gridRow .gridPush.gridPush-6 {
    margin-left: 490px;
  }
  .gridRow .gridPush.gridPush-6.gridCol-press-left,
  .gridRow .gridPush.gridPush-6.gridCol-press {
    margin-left: 480px !important;
  }
  .gridRow .gridPush.gridPush-7 {
    margin-left: 570px;
  }
  .gridRow .gridPush.gridPush-7.gridCol-press-left,
  .gridRow .gridPush.gridPush-7.gridCol-press {
    margin-left: 560px !important;
  }
  .gridRow .gridPush.gridPush-8 {
    margin-left: 650px;
  }
  .gridRow .gridPush.gridPush-8.gridCol-press-left,
  .gridRow .gridPush.gridPush-8.gridCol-press {
    margin-left: 640px !important;
  }
  .gridRow .gridPush.gridPush-9 {
    margin-left: 730px;
  }
  .gridRow .gridPush.gridPush-9.gridCol-press-left,
  .gridRow .gridPush.gridPush-9.gridCol-press {
    margin-left: 720px !important;
  }
  .gridRow .gridPush.gridPush-10 {
    margin-left: 810px;
  }
  .gridRow .gridPush.gridPush-10.gridCol-press-left,
  .gridRow .gridPush.gridPush-10.gridCol-press {
    margin-left: 800px !important;
  }
  .gridRow .gridPush.gridPush-11 {
    margin-left: 890px;
  }
  .gridRow .gridPush.gridPush-11.gridCol-press-left,
  .gridRow .gridPush.gridPush-11.gridCol-press {
    margin-left: 880px !important;
  }
  .gridRow .gridPush.gridPush-12 {
    margin-left: 970px;
  }
  .gridRow .gridPush.gridPush-12.gridCol-press-left,
  .gridRow .gridPush.gridPush-12.gridCol-press {
    margin-left: 960px !important;
  }
  .gridRow * .gridRow :first-child.gridCol-1,
  .gridRow * .gridRow :first-child.gridCol-2,
  .gridRow * .gridRow :first-child.gridCol-3,
  .gridRow * .gridRow :first-child.gridCol-4,
  .gridRow * .gridRow :first-child.gridCol-5,
  .gridRow * .gridRow :first-child.gridCol-6,
  .gridRow * .gridRow :first-child.gridCol-7,
  .gridRow * .gridRow :first-child.gridCol-8,
  .gridRow * .gridRow :first-child.gridCol-9,
  .gridRow * .gridRow :first-child.gridCol-10,
  .gridRow * .gridRow :first-child.gridCol-11,
  .gridRow * .gridRow :first-child.gridCol-12 {
    margin-left: 0;
  }
  .gridRow * .gridRow :last-child.gridCol-1,
  .gridRow * .gridRow :last-child.gridCol-2,
  .gridRow * .gridRow :last-child.gridCol-3,
  .gridRow * .gridRow :last-child.gridCol-4,
  .gridRow * .gridRow :last-child.gridCol-5,
  .gridRow * .gridRow :last-child.gridCol-6,
  .gridRow * .gridRow :last-child.gridCol-7,
  .gridRow * .gridRow :last-child.gridCol-8,
  .gridRow * .gridRow :last-child.gridCol-9,
  .gridRow * .gridRow :last-child.gridCol-10,
  .gridRow * .gridRow :last-child.gridCol-11,
  .gridRow * .gridRow :last-child.gridCol-12 {
    margin-right: 0;
  }
  .gridRow * .gridRow .gridPush.gridPush-1 {
    margin-left: 90px;
  }
  .gridRow * .gridRow .gridPush.gridPush-1.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-1.gridCol-press {
    margin-left: 80px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-2 {
    margin-left: 170px;
  }
  .gridRow * .gridRow .gridPush.gridPush-2.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-2.gridCol-press {
    margin-left: 160px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-3 {
    margin-left: 250px;
  }
  .gridRow * .gridRow .gridPush.gridPush-3.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-3.gridCol-press {
    margin-left: 240px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-4 {
    margin-left: 330px;
  }
  .gridRow * .gridRow .gridPush.gridPush-4.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-4.gridCol-press {
    margin-left: 320px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-5 {
    margin-left: 410px;
  }
  .gridRow * .gridRow .gridPush.gridPush-5.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-5.gridCol-press {
    margin-left: 400px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-6 {
    margin-left: 490px;
  }
  .gridRow * .gridRow .gridPush.gridPush-6.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-6.gridCol-press {
    margin-left: 480px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-7 {
    margin-left: 570px;
  }
  .gridRow * .gridRow .gridPush.gridPush-7.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-7.gridCol-press {
    margin-left: 560px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-8 {
    margin-left: 650px;
  }
  .gridRow * .gridRow .gridPush.gridPush-8.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-8.gridCol-press {
    margin-left: 640px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-9 {
    margin-left: 730px;
  }
  .gridRow * .gridRow .gridPush.gridPush-9.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-9.gridCol-press {
    margin-left: 720px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-10 {
    margin-left: 810px;
  }
  .gridRow * .gridRow .gridPush.gridPush-10.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-10.gridCol-press {
    margin-left: 800px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-11 {
    margin-left: 890px;
  }
  .gridRow * .gridRow .gridPush.gridPush-11.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-11.gridCol-press {
    margin-left: 880px !important;
  }
  .gridRow * .gridRow .gridPush.gridPush-12 {
    margin-left: 970px;
  }
  .gridRow * .gridRow .gridPush.gridPush-12.gridCol-press-left,
  .gridRow * .gridRow .gridPush.gridPush-12.gridCol-press {
    margin-left: 960px !important;
  }
}
@media (max-width: 1023px) {
  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }
  .gridRow {
    width: 100%;
  }
  .gridRow .gridCol-12,
  .gridRow .gridCol-11,
  .gridRow .gridCol-10,
  .gridRow .gridCol-9,
  .gridRow .gridCol-8,
  .gridRow .gridCol-7,
  .gridRow .gridCol-6,
  .gridRow .gridCol-5,
  .gridRow .gridCol-4,
  .gridRow .gridCol-3,
  .gridRow .gridCol-2,
  .gridRow .gridCol-1 {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .gridRow .gridPush.gridPush-1,
  .gridRow .gridPush.gridPush-2,
  .gridRow .gridPush.gridPush-3,
  .gridRow .gridPush.gridPush-4,
  .gridRow .gridPush.gridPush-5,
  .gridRow .gridPush.gridPush-6,
  .gridRow .gridPush.gridPush-7,
  .gridRow .gridPush.gridPush-8,
  .gridRow .gridPush.gridPush-9,
  .gridRow .gridPush.gridPush-10,
  .gridRow .gridPush.gridPush-11,
  .gridRow .gridPush.gridPush-12 {
    margin-left: 0;
  }
  .gridRow * .gridRow .gridPush.gridPush-1,
  .gridRow * .gridRow .gridPush.gridPush-2,
  .gridRow * .gridRow .gridPush.gridPush-3,
  .gridRow * .gridRow .gridPush.gridPush-4,
  .gridRow * .gridRow .gridPush.gridPush-5,
  .gridRow * .gridRow .gridPush.gridPush-6,
  .gridRow * .gridRow .gridPush.gridPush-7,
  .gridRow * .gridRow .gridPush.gridPush-8,
  .gridRow * .gridRow .gridPush.gridPush-9,
  .gridRow * .gridRow .gridPush.gridPush-10,
  .gridRow * .gridRow .gridPush.gridPush-11,
  .gridRow * .gridRow .gridPush.gridPush-12 {
    margin-left: 0;
  }
}
/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 18:26
 */
/* ----------------------------------------------------------
	Body
-----------------------------------------------------------*/
body {
  font-family: 'Arial', sans-serif;
  color: #222222;
  background: #ffffff;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  overflow-y: scroll;
}
.showOnlyOn-phone {
  display: none;
}
/* ----------------------------------------------------------
	Typography
-----------------------------------------------------------*/
a {
  text-decoration: none;
  color: #a1c167;
}
a:hover {
  color: #b7d08b;
}
ul,
ol {
  padding: 0;
  margin: 0 0 10px 25px;
}
.hl-special {
  font-size: 32px;
  font-weight: 300;
  line-height: 1.3;
  margin: 40px 0 20px;
}
.hl-special:first-child {
  margin-top: 0;
}
.hl-1 {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  margin: 40px 0 14px;
}
.hl-1:first-child {
  margin-top: 0;
}
.hl-2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.3;
  margin: 40px 0 16px;
}
.hl-2:first-child {
  margin-top: 0;
}
.hl-3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.3;
  margin: 40px 0 16px;
}
.hl-3:first-child {
  margin-top: 0;
}
.hl-label {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 20px;
  color: #585858;
}
.sidebar h2:not(.hl-1):not(.hl-2):not(.hl-3):not(.hl-special),
.textBox h2:not(.hl-1):not(.hl-2):not(.hl-3):not(.hl-special) {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  margin: 40px 0 14px;
}
.sidebar h2:not(.hl-1):not(.hl-2):not(.hl-3):not(.hl-special):first-child,
.textBox h2:not(.hl-1):not(.hl-2):not(.hl-3):not(.hl-special):first-child {
  margin-top: 0;
}
.sidebar h2:not(.hl-1):not(.hl-2):not(.hl-3):not(.hl-special):first-child,
.textBox h2:not(.hl-1):not(.hl-2):not(.hl-3):not(.hl-special):first-child {
  margin-top: 0;
}
.pageContents p:first-child {
  margin-top: 0;
}
/* ----------------------------------------------------------
	buttons
-----------------------------------------------------------*/
a.btn,
.btn,
input.btn,
button.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  text-decoration: none;
  transition: 0.3s all;
  text-align: center;
  color: #a1c167;
  border: 1px solid;
  cursor: pointer;
  height: auto;
}
a.btn:hover,
.btn:hover,
input.btn:hover,
button.btn:hover,
a.btn.btn-icon.act,
.btn.btn-icon.act,
input.btn.btn-icon.act,
button.btn.btn-icon.act {
  text-decoration: none;
  color: #FFF;
  background: #a1c167;
  border-color: #a1c167;
}
a.btn.btn-icon,
.btn.btn-icon,
input.btn.btn-icon,
button.btn.btn-icon {
  padding: 0;
  width: 30px;
  height: 30px;
  line-height: 1;
  padding-top: 6px;
}
a.btn.btn-hero,
.btn.btn-hero,
input.btn.btn-hero,
button.btn.btn-hero {
  background: #a1c167;
  color: #FFF !important;
  border-color: #a1c167;
}
a.btn.btn-hero:hover,
.btn.btn-hero:hover,
input.btn.btn-hero:hover,
button.btn.btn-hero:hover {
  background: #b7d08b;
}
.controls-switchGroup .btn,
.controls-formControls .btn,
.bar-filter .btn,
.controls-switchGroup a.btn,
.controls-formControls a.btn,
.bar-filter a.btn {
  color: #919191;
  padding: 5px 10px;
  width: 120px;
}
.controls-switchGroup .btn:hover,
.controls-formControls .btn:hover,
.bar-filter .btn:hover,
.controls-switchGroup a.btn:hover,
.controls-formControls a.btn:hover,
.bar-filter a.btn:hover {
  color: #FFF;
}
.controls-switchGroup .btn:not(.btn-hero):hover,
.controls-formControls .btn:not(.btn-hero):hover,
.bar-filter .btn:not(.btn-hero):hover,
.controls-switchGroup a.btn:not(.btn-hero):hover,
.controls-formControls a.btn:not(.btn-hero):hover,
.bar-filter a.btn:not(.btn-hero):hover {
  border-color: #919191;
  background: #919191;
}
.controls-switchGroup .btn.act,
.controls-formControls .btn.act,
.bar-filter .btn.act,
.controls-switchGroup a.btn.act,
.controls-formControls a.btn.act,
.bar-filter a.btn.act {
  color: #a1c167;
}
.controls-switchGroup .btn.act:hover,
.controls-formControls .btn.act:hover,
.bar-filter .btn.act:hover,
.controls-switchGroup a.btn.act:hover,
.controls-formControls a.btn.act:hover,
.bar-filter a.btn.act:hover {
  color: #FFF !important;
  background: #a1c167;
  border-color: #a1c167;
}
.controls-formControls {
  border-top: 1px solid #DDD;
  margin-top: 20px;
  padding-top: 20px;
}
.controls-formControls .btn,
.controls-formControls a.btn {
  margin-right: 10px;
}
.controls-formControls .btn:last-child,
.controls-formControls a.btn:last-child {
  margin-right: 0;
}
a.iconSolo,
.iconSolo {
  display: inline-block;
}
.controls-cmsControls {
  text-align: right;
}
.link-learnMore,
a.link-learnMore {
  display: block;
  color: #005795;
}
.link-learnMore::after,
a.link-learnMore::after {
  content: "";
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  margin-left: 10px;
  font-size: 0.7em;
}
.link-download::after,
a.link-download::after {
  content: "";
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  margin-left: 10px;
  font-size: 0.7em;
}
/* ----------------------------------------------------------
	forms
-----------------------------------------------------------*/
input,
select,
textarea,
button {
  border: 1px solid #DDD;
  background: #ffffff;
  font-family: 'Arial', sans-serif;
  color: #222222;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px 10px;
  vertical-align: middle;
  transition: 0.3s all;
  border-radius: 0;
}
input[type="range"],
select[type="range"],
textarea[type="range"],
button[type="range"] {
  border: 0;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  background: #f5f5f5;
  outline: none;
}
input[type="text"],
input[type="email"],
input[type="password"],
select,
textarea {
  width: 350px;
}
input,
select {
  height: 30px;
}
input[multiple],
select[multiple] {
  height: 200px;
}
optgroup,
option {
  background: #ffffff;
  font-family: 'Arial', sans-serif;
  color: #222222;
  font-size: 14px;
  line-height: 1.5;
}
input[type="button"][disabled],
input[type="submit"][disabled],
input[type="reset"][disabled],
button[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}
/* ----------------------------------------------------------
	cake inputs
-----------------------------------------------------------*/
.input {
  margin-bottom: 15px;
}
.input label {
  display: inline-block;
  min-width: 150px;
}
.input .inputHelp {
  display: inline-block;
  padding-left: 150px;
  font-size: 13px;
  margin-top: 5px;
  color: #a1c167;
}
.input.rte [data-content-editable] {
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid #DDD;
}
.input.files [data-file-upload-inner-save-handling-table] {
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid #DDD;
}
.input.files [data-file-upload-inner-save-handling-table] [data-file-upload-inner-save-handling-template] {
  display: none;
}
.input.error input,
.input.error select {
  border-color: #f00;
}
.error-message {
  color: #f00;
}
#JobAddEditForm .error-message {
  padding-left: 50px;
}
#JobAddEditForm .error-message:before {
  content: '!';
  border: 2px solid #f00;
  border-radius: 50px;
  color: #f00;
  font-size: 26px;
  line-height: 26px;
  text-align: center;
  padding: 7px;
  position: absolute;
  width: 40px;
  height: 40px;
  display: block;
  left: 10px;
}
.submit {
  display: inline-block;
  vertical-align: top;
}
/* ----------------------------------------------------------
	Header
-----------------------------------------------------------*/
.block-header {
  background: #FFF;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
  border-bottom: 1px solid #DDD;
}
.block-header .pageLogo {
  display: inline-block;
  margin-top: 20px;
  height: 60px;
}
.block-header .pageLogo a {
  display: block;
  height: 100%;
}
.block-header .pageLogo a img {
  display: block;
  height: 100%;
}
.block-header .menuIcon {
  display: none;
}
.block-header .loggedBox {
  position: absolute;
  right: 0;
  top: 17px;
  padding-right: 20px;
  padding-top: 15px;
  text-transform: uppercase;
  color: #585858;
  font-size: 13px;
}
.block-header .loggedBox a {
  color: #919191;
}
.block-header .loggedBox .loggedUserBox {
  display: inline-block;
}
.block-header .loggedBox .loggedUserBox img {
  display: inline-block;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  background: #DDD;
  margin-right: 10px;
  vertical-align: middle;
}
.block-header .loggedBox .loggedUserBox .userInfo {
  display: inline-block;
  height: 20px;
  position: relative;
}
.block-header .loggedBox .loggedUserBox .userInfo .userInfo-name {
  font-size: 18px;
  font-weight: 700;
  text-transform: none;
}
.block-header .loggedBox .loggedUserBox .userInfo .userInfo-role {
  position: absolute;
  bottom: -25px;
  left: 0;
}
.block-header .loggedBox .metaNavi {
  display: inline-block;
  height: 20px;
}
.block-header .loggedBox .metaNavi .nav-separator {
  display: inline-block;
  margin: 0 10px;
}
.block-header .loggedBox .metaNavi .moreBox {
  display: inline-block;
}
/* ----------------------------------------------------------
	content
-----------------------------------------------------------*/
.block-content {
  padding-top: 100px;
  position: relative;
  min-height: calc(100vh - 78px);
}
/* ----------------------------------------------------------
	breadcrump
-----------------------------------------------------------*/
.breadcrump {
  font-size: 14px;
}
.breadcrump a,
.breadcrump span {
  margin-right: 10px;
  text-decoration: none;
}
.breadcrump a::before,
.breadcrump span::before {
  content: "";
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
  display: inline-block;
  margin-right: 7px;
  font-size: 0.7em;
}
/* ----------------------------------------------------------
	pageheader
-----------------------------------------------------------*/
.pageHeader {
  height: 190px;
  padding-top: 40px;
  position: relative;
}
.pageHeader a {
  color: #005795;
}
.pageHeader a.btn {
  color: #a1c167;
}
.pageHeader a.btn:not(.btn-hero):hover {
  color: #FFF;
  background: #a1c167;
  border-color: #a1c167;
}
.pageHeader a:hover {
  color: #0075c8;
}
.pageHeader .hl-1 .btn {
  position: relative;
  top: -7px;
}
.pageHeader .pageSubNavigation {
  font-size: 19px;
  position: absolute;
  bottom: 4px;
  left: 0;
}
.pageHeader .pageSubNavigation a {
  text-decoration: none;
  display: inline-block;
  margin-right: 15px;
  font-weight: 300;
}
.pageHeader .pageSubNavigation .controls-addPage {
  display: inline-block;
  color: #a1c167;
}
.pageHeader .pageSubNavigation .controls-addPage a {
  color: #a1c167;
}
.pageHeader .pageSubNavigation .controls-addPage a:hover {
  color: #b7d08b;
}
.pageHeader .pageSubNavigation .controls-movePage {
  font-size: 0.7em;
}
.pageHeader .hl-1 {
  font-size: 38px;
  font-weight: 300;
  margin: 0 0 20px;
}
.pageHeader .hl-1 .iconSolo {
  transform: scaleX(-1);
  font-size: 0.7em;
  margin-right: 20px;
}
/* ----------------------------------------------------------
	pagecontents
-----------------------------------------------------------*/
.pageContents {
  border-top: 1px solid #DDD;
  padding-bottom: 80px;
  padding-top: 15px;
}
.pageContents .hl-3 {
  font-size: 14px;
  font-weight: 700;
}
.pageContents p {
  max-width: 820px;
}
.pageContents .contenttools_elem {
  *zoom: 1;
  border: solid 1px #dddddd;
  padding: 15px 0;
}
.pageContents .contenttools_elem:before,
.pageContents .contenttools_elem:after {
  display: table;
  content: "";
  line-height: 0;
}
.pageContents .contenttools_elem:after {
  clear: both;
}
.pageContents .contenttools_elem .ce-element--type-text {
  cursor: text;
  padding: 0 5px;
}
.pageContents .contenttools_elem image,
.pageContents .contenttools_elem [data-ce-tag=img],
.pageContents .contenttools_elem img,
.pageContents .contenttools_elem video,
.pageContents .contenttools_elem .ce-element--type-video,
.pageContents .contenttools_elem iframe,
.pageContents .contenttools_elem .ce-element--type-image {
  clear: both;
  display: block;
  max-width: 100%;
  margin-bottom: 5px;
  margin-left: initial;
  margin-right: initial;
}
.pageContents .contenttools_elem image.ce-element--type-image,
.pageContents .contenttools_elem [data-ce-tag=img].ce-element--type-image,
.pageContents .contenttools_elem img.ce-element--type-image,
.pageContents .contenttools_elem video.ce-element--type-image,
.pageContents .contenttools_elem .ce-element--type-video.ce-element--type-image,
.pageContents .contenttools_elem iframe.ce-element--type-image,
.pageContents .contenttools_elem .ce-element--type-image.ce-element--type-image {
  background-size: contain;
}
.pageContents .contenttools_elem image.align-left,
.pageContents .contenttools_elem [data-ce-tag=img].align-left,
.pageContents .contenttools_elem img.align-left,
.pageContents .contenttools_elem video.align-left,
.pageContents .contenttools_elem .ce-element--type-video.align-left,
.pageContents .contenttools_elem iframe.align-left,
.pageContents .contenttools_elem .ce-element--type-image.align-left {
  clear: initial;
  float: left;
  margin-right: 20px;
}
.pageContents .contenttools_elem image.align-right,
.pageContents .contenttools_elem [data-ce-tag=img].align-right,
.pageContents .contenttools_elem img.align-right,
.pageContents .contenttools_elem video.align-right,
.pageContents .contenttools_elem .ce-element--type-video.align-right,
.pageContents .contenttools_elem iframe.align-right,
.pageContents .contenttools_elem .ce-element--type-image.align-right {
  clear: initial;
  float: right;
  margin-left: 20px;
}
.pageContents .contenttools_elem .text-center {
  text-align: center;
}
.pageContents .contenttools_elem .text-left {
  text-align: left;
}
.pageContents .contenttools_elem .text-right {
  text-align: right;
}
.pageContents .controls-addContent {
  padding: 10px;
  border: 1px dashed #DDD;
  margin: 30px 0 50px;
  color: #a1c167;
}
.pageContents .controls-addContent .btn {
  vertical-align: middle;
}
.pageContents .sidebar .controls-addContent select {
  width: 230px;
}
.pageContents .controls-editContent {
  border-right: 1px dashed #DDD;
  border-top: 1px dashed #DDD;
  color: #a1c167;
  margin: 60px 0 0;
  padding: 5px;
  text-align: right;
}
.pageContents .bar-filter {
  border-top: 1px solid #DDD;
  border-bottom: 1px solid #DDD;
  margin-bottom: 10px;
  padding: 10px 0;
}
.pageContents .bar-filter:first-child {
  border-top: none;
  padding-top: 0;
}
.pageContents .bar-filter .filterLabel {
  margin-bottom: 10px;
  font-size: 13px;
}
.pageContents .bar-filter input[type="text"],
.pageContents .bar-filter input[type="email"],
.pageContents .bar-filter input[type="password"],
.pageContents .bar-filter select,
.pageContents .bar-filter textarea {
  width: 250px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-color: #005795;
  border-width: 1px;
  height: 32px;
}
.pageContents .bar-filter textarea {
  height: auto;
}
.pageContents .bar-filter .aboBox {
  background: #eee;
  display: inline-block;
  padding: 11px;
  margin: 10px 0;
}
.pageContents .bar-filter .aboBox .aboBox-label {
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
}
.pageContents .bar-filter .aboBox .btn,
.pageContents .bar-filter .aboBox a.btn {
  width: auto;
  color: #a1c167;
}
.pageContents table:not(.table-downloads):not(.table-index) {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.pageContents table:not(.table-downloads):not(.table-index) tr th,
.pageContents table:not(.table-downloads):not(.table-index) tr td {
  border: 1px solid #DDD;
  padding: 5px;
  vertical-align: top;
}
.pageContents table:not(.table-downloads):not(.table-index) tr th {
  text-align: left;
  font-weight: 700;
}
.pageContents table.table-downloads tr td .heading,
.pageContents table.table-downloads tr td .subheading {
  max-width: 500px;
  word-wrap: break-word;
}
.pageContents table.table-downloads,
.pageContents table.table-index {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.pageContents table.table-downloads tr th,
.pageContents table.table-index tr th,
.pageContents table.table-downloads tr td,
.pageContents table.table-index tr td {
  border-right: 20px solid transparent;
  position: relative;
}
.pageContents table.table-downloads tr th:last-child,
.pageContents table.table-index tr th:last-child,
.pageContents table.table-downloads tr td:last-child,
.pageContents table.table-index tr td:last-child {
  border-right: 0;
}
.pageContents table.table-downloads tr th,
.pageContents table.table-index tr th {
  text-align: left;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  padding-bottom: 14px;
  font-size: 13px;
}
.pageContents table.table-downloads tr th::after,
.pageContents table.table-index tr th::after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 0;
  border-bottom: 1px solid #C7C7C7;
  bottom: 10px;
}
.pageContents table.table-downloads tr th a,
.pageContents table.table-index tr th a {
  color: #585858;
  text-decoration: none;
  display: block;
}
.pageContents table.table-downloads tr th a:hover,
.pageContents table.table-index tr th a:hover {
  color: #727272;
}
.pageContents table.table-downloads tr th a::after,
.pageContents table.table-index tr th a::after {
  margin-lft: 5px;
  display: inline-block;
}
.pageContents table.table-downloads tr th a.desc::after,
.pageContents table.table-index tr th a.desc::after,
.pageContents table.table-downloads tr th a.asc::after,
.pageContents table.table-index tr th a.asc::after {
  display: inline-block;
  margin-left: 7px;
}
.pageContents table.table-downloads tr th a.desc::after,
.pageContents table.table-index tr th a.desc::after {
  content: "";
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}
.pageContents table.table-downloads tr th a.asc::after,
.pageContents table.table-index tr th a.asc::after {
  content: "";
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}
.pageContents table.table-downloads tr td,
.pageContents table.table-index tr td {
  padding-bottom: 21px;
}
.pageContents table.table-downloads tr td::after,
.pageContents table.table-index tr td::after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 0;
  border-bottom: 1px dashed #C7C7C7;
  bottom: 10px;
}
.pageContents table.table-downloads tr td .heading,
.pageContents table.table-index tr td .heading {
  font-weight: 400;
}
.pageContents table.table-downloads tr td .subheading,
.pageContents table.table-index tr td .subheading {
  color: #919191;
  font-size: 12px;
}
.pageContents table.table-downloads tr td .icon,
.pageContents table.table-index tr td .icon {
  font-size: 25px;
  margin-right: 5px;
}
.pageContents table.table-downloads tr:last-child td::after,
.pageContents table.table-index tr:last-child td::after {
  border-bottom: 1px solid #C7C7C7;
}
.pageContents table.table-downloads tr .tablecol-actions,
.pageContents table.table-index tr .tablecol-actions {
  width: 134px;
}
.pageContents .thumb-mini {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  background: #000;
}
.pageContents .thumb-mini img {
  display: block;
  width: 100%;
  opacity: 0.5;
}
.pageContents .thumb-mini span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  color: #FFF;
  text-align: center;
  line-height: 30px;
}
.pageContents .contentSection {
  margin-bottom: 50px;
  position: relative;
}
.pageContents .contentSection.contentSection-bordered {
  border-top: 1px solid #DDD;
}
.pageContents .contentSection.contentSection-bordered:first-child {
  border-top: none;
}
.pageContents .teasers-prominent {
  margin-bottom: 40px;
}
.pageContents .teaser {
  border: 1px solid #DDD;
  margin-bottom: 10px;
}
.pageContents .teaser .teaser_content {
  color: #919191;
  padding: 15px;
}
.pageContents .teaser .teaser_content .heading {
  font-weight: 400;
  color: #222222;
}
.pageContents .teaser .teaser_footer {
  color: #919191;
}
.pageContents .teaser.teaser-imagetile .teaser_img,
.pageContents .teaser.teaser-listitem .teaser_img {
  padding-top: 67%;
  background-size: cover;
  background-position: center center;
  background-color: #DDD;
}
.pageContents .teaser.teaser-imagetile .teaser_content,
.pageContents .teaser.teaser-listitem .teaser_content {
  font-size: 13px;
}
.pageContents .teaser.teaser-imagetile .teaser_content .heading,
.pageContents .teaser.teaser-listitem .teaser_content .heading {
  margin: 0 0 5px;
  font-size: 17px;
  line-height: 1.3;
  color: #222222;
  height: 2.6em;
  overflow: hidden;
}
.pageContents .teaser.teaser-imagetile .teaser_content .subheading,
.pageContents .teaser.teaser-listitem .teaser_content .subheading {
  height: 3em;
  overflow: hidden;
}
.pageContents .teaser.teaser-imagetile .teaser_content .link-learnMore,
.pageContents .teaser.teaser-listitem .teaser_content .link-learnMore {
  font-size: 15px;
}
.pageContents .teaser.teaser-imagetile .teaser_footer {
  position: relative;
  padding: 0 15px 5px;
  font-size: 13px;
}
.pageContents .teaser.teaser-imagetile .teaser_footer .control-bookmark {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.pageContents .teaser.teaser-listitem {
  border: 0;
  margin-bottom: 50px;
}
.pageContents .teaser.teaser-listitem .teaser_content {
  padding-top: 0;
}
.pageContents .teaser.teaser-listitem .teaser_footer {
  margin-top: 20px;
}
.pageContents .teaser.teaser-subpage:hover {
  background: #EEE;
}
.pageContents .teaser.teaser-subpage .teaser_content {
  min-height: 110px;
}
.pageContents .teaser.teaser-subpage .teaser_content .heading {
  margin: 0 0 5px;
  font-size: 15px;
  word-wrap: break-word;
}
.pageContents .teaser.teaser-subpage .teaser_content .subheading {
  font-size: 12px;
}
.pageContents .teaser.teaser-referatteam {
  margin-left: 1% !important;
  margin-right: 1% !important;
  width: 32% !important;
}
.pageContents .teaser.teaser-referatteam .teaser_content {
  min-height: 190px;
}
.pageContents .teaser.teaser-referatteam .teaser_content .heading {
  margin: 0 0 5px;
  font-size: 15px;
}
.pageContents .teaser.teaser-referatteam .teaser_content .subheading {
  font-size: 12px;
}
.pageContents .teaser.teaser-referatteam.gridCol-3:nth-child(3n) {
  margin-right: 0 !important;
}
.pageContents .teaser.teaser-referatteam.gridCol-3:nth-child(3n+1) {
  margin-left: 0 !important;
}
.pageContents .teaser.gridCol-3:nth-child(4n) {
  margin-right: 0;
}
.pageContents .teaser.gridCol-3:nth-child(4n+1) {
  margin-left: 0;
}
.pageContents .btn-tile {
  display: block;
  height: 385px;
}
.pageContents .btn-tile span {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.pageContents .searchResultsIntro {
  font-size: 20px;
}
.pageContents .search-resultbox {
  border-top: 1px solid #DDD;
  margin-top: 50px;
  padding-top: 10px;
}
.pageContents .search-resultbox h4 {
  font-size: 14px;
  font-weight: 700;
  font-weight: 300;
  margin-bottom: 0;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result {
  margin-bottom: 35px;
  background: #eee;
  padding: 20px;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .highlight {
  background: rgba(255, 255, 0, 0.4);
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result > .hl-2 {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3;
  margin: 40px 0 14px;
  color: #005795;
  margin: 0 0 10px;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result > .hl-2:first-child {
  margin-top: 0;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result > .hl-2:first-child {
  margin-top: 0;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result > .hl-2 a {
  color: #005795;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result > .hl-3 {
  font-size: 14px;
  font-weight: 700;
  font-weight: 300;
  margin: 0;
  margin-top: 5px;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content {
  margin-top: 5px;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content :first-child {
  margin-top: 0;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content h1,
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content h2,
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content h3 {
  font-weight: 400;
  font-size: 20px;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content img {
  display: none;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content > p:last-of-type {
  margin-bottom: 0;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result .search-resultbox-results-result-content > p:first-of-type {
  margin-top: 0;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result > p {
  margin: 5px 0 0 0;
  font-size: 13px;
}
.pageContents .search-resultbox .search-resultbox-results .search-resultbox-results-result > p a {
  color: #919191;
}
.pageContents .search-resultbox .search-resultbox-more {
  margin-top: 30px;
}
.pageContents .search-resultbox .search-resultbox-more .resultsInfo {
  font-style: italic;
}
/* ----------------------------------------------------------
	Footer
-----------------------------------------------------------*/
.block-footer {
  background: #505050;
  color: #FFF;
  padding: 18px 30px 18px 70px;
  line-height: 40px;
  height: 78px;
}
.block-footer a {
  color: #FFF;
  text-decoration: none;
}
.block-footer a:hover {
  color: #f5f5f5;
}
.block-footer .part-copyright {
  display: inline-block;
  margin-right: 40px;
}
.block-footer .part-social {
  display: inline-block;
}
.block-footer .part-social a.linkSocial {
  border: 1px solid;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  height: 40px;
  width: 40px;
  line-height: 1;
  padding-top: 9px;
  vertical-align: middle;
  margin: 0 5px;
  font-size: 20px;
}
.block-footer .part-metanav {
  position: absolute;
  right: 30px;
  display: inline-block;
}
.block-footer .part-metanav a {
  margin-left: 10px;
}
/* ----------------------------------------------------------
	contact
-----------------------------------------------------------*/
.contact {
  margin-bottom: 20px;
}
.contact .contact_name {
  font-weight: 700;
}
.contact .contact_position {
  color: #919191;
}
.contact .contact_data {
  margin-top: 20px;
}
.contact .contact_data .contact_data_line .phoneLabel {
  font-weight: 700;
}
/* ----------------------------------------------------------
	block sidebar
-----------------------------------------------------------*/
.block-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  color: #404040;
  background: #AAA;
  width: 40px;
  transition: 0.3s width;
}
.block-sidebar a {
  color: #404040;
  font-weight: 700;
}
.block-sidebar a:hover {
  color: #5a5a5a;
}
.block-sidebar .btn:hover,
.block-sidebar a.btn:hover {
  background: none;
  border-color: #5a5a5a;
  color: #5a5a5a;
}
.block-sidebar.exp {
  width: 400px;
  max-width: 100%;
}
.block-sidebar.exp .contentCollapsed {
  display: none;
}
.block-sidebar.exp .contentExpanded {
  display: block;
  height: 100%;
  overflow-y: auto;
}
.block-sidebar .loggedBox {
  display: none;
}
.block-sidebar .contentExpanded {
  display: none;
  padding: 30px 40px;
}
.block-sidebar .menuIcon {
  text-align: center;
  font-size: 30px;
  cursor: pointer;
  margin-top: 100px;
}
.block-sidebar input,
.block-sidebar .btn {
  width: 100%;
  display: block;
}
.block-sidebar ul,
.block-sidebar ol {
  margin: 0;
  padding: 0;
}
.block-sidebar ul li,
.block-sidebar ol li {
  margin-bottom: 5px;
}
.block-sidebar ul li a,
.block-sidebar ol li a {
  padding: 5px 0;
  display: block;
}
.block-sidebar ul li ul,
.block-sidebar ol li ul {
  margin-left: 20px;
}
.block-sidebar .sidebar_search {
  margin-bottom: 40px;
}
.block-sidebar .sidebar_search input {
  height: 40px;
}
.block-sidebar .sidebar_hotlink {
  margin-bottom: 20px;
}
.block-sidebar .sidebar_nav {
  margin-bottom: 30px;
}
.block-sidebar .togglerNav_entry {
  position: relative;
}
.block-sidebar .togglerNav_entry .togglerNav_toggler {
  position: absolute;
  left: 20px;
  left: -20px;
  top: 7px;
  cursor: pointer;
}
.block-sidebar .togglerNav_entry .togglerNav_toggler::after {
  content: "";
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}
.block-sidebar .togglerNav_entry.exp .togglerNav_toggler::after {
  content: "";
  font-family: "themify";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}
.block-sidebar .togglerNav_entry .g8_toggleable_toggledContents {
  display: none;
}
.sidebarCover {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 199;
}
/* ----------------------------------------------------------
	debugging & dev
-----------------------------------------------------------*/
.cake-sql-log {
  margin: 10px 100px 20px;
  color: #999;
}
/* ----------------------------------------------------------
	downloadBox,
	imageBox,
	tilesBox,
	contactBox
-----------------------------------------------------------*/
.downloadBox .hl-1,
.imageBox .hl-1,
.tilesBox .hl-1,
.downloadBox .hl-2,
.imageBox .hl-2,
.tilesBox .hl-2,
.downloadBox .hl-3,
.imageBox .hl-3,
.tilesBox .hl-3,
.downloadBox h1,
.imageBox h1,
.tilesBox h1,
.downloadBox h2,
.imageBox h2,
.tilesBox h2,
.downloadBox h3,
.imageBox h3,
.tilesBox h3,
.downloadBox h4,
.imageBox h4,
.tilesBox h4 {
  font-size: 14px;
  font-weight: 400;
  margin: 5px 0 20px;
  color: #585858;
}
.imageBox .image {
  height: 150px;
  background-size: cover;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
}
.imageBox .gridCol-2:nth-child(4n),
.tilesBox .gridCol-2:nth-child(4n) {
  margin-right: 0;
}
.imageBox .gridCol-2:nth-child(4n+1),
.tilesBox .gridCol-2:nth-child(4n+1) {
  margin-left: 0;
}
.contactBox select,
.contactBox input {
  width: 100%;
}
.contactBox .hl-2 {
  font-size: 16px;
}
.contactBox .hl-2 small {
  line-height: 1.3;
  display: inline-block;
  font-size: 12px;
  margin-top: 5px;
}
/* ----------------------------------------------------------
	controls
-----------------------------------------------------------*/
.controls-pageViewMode {
  background: #ffffff;
  padding: 20px;
  position: fixed;
  right: 0;
  top: 100px;
  z-index: 100;
  width: 300px;
}
.controls-pageViewMode .controlsLabel {
  font-size: 12px;
  margin-bottom: 5px;
  color: #585858;
}
/* ----------------------------------------------------------
	noContentsAvailable
-----------------------------------------------------------*/
.noContentsAvailable {
  background: #EEE;
  font-style: italic;
  padding: 5px;
}
/* ----------------------------------------------------------
	back to overview
-----------------------------------------------------------*/
.box-backToOverview {
  border-top: 1px solid #DDD;
  font-size: 16px;
  margin-top: 30px;
  padding-top: 10px;
}
.box-backToOverview a.link-backToOverview {
  color: #222222;
}
.box-backToOverview a.link-backToOverview .ti {
  color: #005795;
  display: inline-block;
  margin-left: 10px;
}
/* ----------------------------------------------------------
	pagination
-----------------------------------------------------------*/
.pagination {
  margin-top: 5px;
  text-align: center;
}
.pagination .current,
.pagination a,
.pagination span > .ti {
  padding: 0 5px;
}
.pagination span > .ti {
  visibility: hidden;
}
.pagination .ti {
  font-size: 0.7em;
}
/* ----------------------------------------------------------
	dashboard
-----------------------------------------------------------*/
.dashboard {
  background: url("../../img/page-bg.jpg") no-repeat fixed;
  background-size: cover;
}
.dashboard .dashboard_row {
  *zoom: 1;
  margin: 0 auto;
  padding-top: 30px;
  width: 1110px;
}
.dashboard .dashboard_row:before,
.dashboard .dashboard_row:after {
  display: table;
  content: "";
  line-height: 0;
}
.dashboard .dashboard_row:after {
  clear: both;
}
.dashboard .dashboard_row .dashboard_col-1 {
  float: left;
  width: 630px;
  margin-right: 20px;
}
.dashboard .dashboard_row .dashboard_col-2 {
  float: left;
  width: 460px;
}
.dashboard .flake {
  background: #ffffff;
  position: relative;
  padding: 30px 60px 50px 90px;
  margin-bottom: 20px;
}
.dashboard .flake.flake-news .flake_items::before {
  content: " ";
  border-left: 1px solid #DDD;
  height: 100%;
  left: 0;
  position: absolute;
  left: -30px;
}
.dashboard .flake .flake_items {
  position: relative;
  margin-top: 25px;
}
.dashboard .flake .flake_moreItems {
  margin-top: 30px;
}
.dashboard .flake .flake_icon {
  position: absolute;
  left: 30px;
  top: 40px;
  font-size: 30px;
  color: #005795;
}
.dashboard .flake .hl-flake {
  font-size: 38px;
  font-weight: 300;
  margin: 0;
  line-height: 1.2;
}
.dashboard .flake a.item-list,
.dashboard .flake .item-list {
  border-bottom: 1px solid #DDD;
  display: block;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
}
.dashboard .flake a.item-list .item-title,
.dashboard .flake .item-list .item-title {
  color: #222222;
  font-weight: 700;
  display: block;
}
.dashboard .flake a.item-list .item-subtitle,
.dashboard .flake .item-list .item-subtitle {
  color: #005795;
  display: block;
}
.dashboard .flake a.item-list .item-controls,
.dashboard .flake .item-list .item-controls {
  position: absolute;
  right: 0;
  top: 0;
}
.dashboard .flake .item-news {
  margin-bottom: 25px;
}
.dashboard .flake .item-news:last-child {
  margin-bottom: 0;
}
.dashboard .flake .item-news .date {
  left: -50px;
  position: relative;
  color: #919191;
  background: #FFF;
}
.dashboard .flake .item-news .heading {
  font-weight: 400;
  margin: 3px 0 10px;
  font-size: 17px;
}
.dashboard .flake .item-news p {
  margin: 0;
}
.dashboard .flake .item-job {
  border-bottom: 1px solid #DDD;
  display: block;
  margin-bottom: 20px;
  padding-bottom: 25px;
}
.dashboard .flake .item-job .heading {
  font-weight: 700;
  margin: 0 0 0;
  font-size: 17px;
}
.dashboard .flake .item-job .date {
  color: #919191;
}
/*
 * Copyright 2019 LABOR.digital
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Last modified: 2019.05.15 at 17:17
 */
/* ----------------------------------------------------------
	breakpoint x
-----------------------------------------------------------*/
/* ----------------------------------------------------------
	breakpoint x
-----------------------------------------------------------*/
/* ----------------------------------------------------------
	breakpoint desktop narrow, close to phone
-----------------------------------------------------------*/
@media (max-width: 1080px) AND (min-width: 1024px) {
  .wrapper {
    margin-left: 45px;
  }
}
/* ----------------------------------------------------------
	breakpoint desktop too small for dashboard
-----------------------------------------------------------*/
@media (max-width: 1220px) AND (min-width: 1171px) {
  .dashboard .dashboard_row {
    margin-left: 50px;
  }
}
@media (max-width: 1170px) AND (min-width: 1024px) {
  .dashboard .dashboard_row {
    margin-left: 50px;
  }
  .dashboard .dashboard_row .dashboard_col-1 {
    width: 530px;
  }
  .dashboard .dashboard_row .dashboard_col-2 {
    width: 410px;
  }
}
/* ----------------------------------------------------------
	breakpoint phone
-----------------------------------------------------------*/
@media (max-width: 1023px) {
  /* ----------------------------------------------------------
	  general
  -----------------------------------------------------------*/
  .hideOn-phone {
    display: none;
  }
  .showOnlyOn-phone {
    display: block;
  }
  /* ----------------------------------------------------------
	  sidebar
  -----------------------------------------------------------*/
  .block-sidebar {
    display: none;
  }
  .block-sidebar.exp {
    display: block;
  }
  .block-sidebar .loggedBox {
    display: block;
    border-top: 1px solid #919191;
    padding-top: 10px;
    margin: 100px 0 25px;
  }
  .block-sidebar .loggedBox .userInfo-name {
    font-weight: 700;
    text-transform: none;
  }
  .block-sidebar .loggedBox .userInfo-role {
    font-size: 0.8em;
    text-transform: uppercase;
  }
  .block-sidebar .loggedBox .btn {
    padding: 5px;
    margin-top: 10px;
  }
  /* ----------------------------------------------------------
	  header
  -----------------------------------------------------------*/
  .block-header {
    height: 60px;
  }
  .block-header .pageLogo {
    margin-top: 5px;
    height: 50px;
    position: absolute;
    right: 10px;
  }
  .block-header .pageLogo img {
    height: 50px;
  }
  .block-header .loggedBox {
    display: none;
  }
  .block-header .menuIcon {
    display: inline-block;
    cursor: pointer;
    color: #585858;
    font-size: 30px;
    margin-right: 20px;
    padding-top: 13px;
    vertical-align: top;
  }
  /* ----------------------------------------------------------
	  footer
  -----------------------------------------------------------*/
  .block-footer {
    height: auto;
  }
  .block-footer .part-copyright,
  .block-footer .part-social,
  .block-footer .part-metanav {
    display: block;
    margin: 0 0 20px;
    text-align: center;
    position: static;
  }
  /* ----------------------------------------------------------
	  content
  -----------------------------------------------------------*/
  .block-content {
    padding-top: 70px;
    min-height: 0;
  }
  .controls-pageViewMode {
    position: static;
    width: auto;
    text-align: right;
  }
  .controls-pageViewMode .controlsLabel {
    display: none;
  }
  .controls-pageViewMode a.btn,
  .controls-pageViewMode .btn {
    width: 49%;
  }
  .controls-cmsControls {
    margin-bottom: 15px;
    text-align: left;
  }
  .pageHeader {
    height: auto;
    padding-top: 0;
  }
  .pageHeader .hl-1 {
    word-wrap: break-word;
    font-size: 28px;
  }
  .pageHeader .pageSubNavigation {
    position: static;
    margin-bottom: 20px;
  }
  .pageHeader .pageSubNavigation a {
    display: block;
    line-height: 1.2;
    margin-bottom: 7px;
    margin-right: 0;
  }
  .pageContents {
    padding-bottom: 50px;
  }
  .pageContents p:last-child {
    margin-bottom: 0;
  }
  .pageContents .contenttools_elem img {
    height: auto;
  }
  .pageContents .contentSection:last-child {
    margin-bottom: 0;
  }
  .pageContents .sidebar {
    padding-top: 40px;
  }
  .pageContents table.table-downloads tr th,
  .pageContents table.table-index tr th,
  .pageContents table.table-downloads tr td,
  .pageContents table.table-index tr td {
    border-right: 0;
  }
  .pageContents table.table-downloads tr th .heading,
  .pageContents table.table-index tr th .heading,
  .pageContents table.table-downloads tr td .heading,
  .pageContents table.table-index tr td .heading {
    word-wrap: break-word;
  }
  .pageContents table.table-downloads tr th .subheading,
  .pageContents table.table-index tr th .subheading,
  .pageContents table.table-downloads tr td .subheading,
  .pageContents table.table-index tr td .subheading {
    word-break: break-all;
  }
  .pageContents .bar-filter input[type="text"],
  .pageContents .bar-filter input[type="email"],
  .pageContents .bar-filter input[type="password"],
  .pageContents .bar-filter select,
  .pageContents .bar-filter textarea {
    width: 100%;
  }
  .pageContents .bar-filter .submit {
    display: block;
  }
  .pageContents .bar-filter .btn,
  .pageContents .bar-filter a.btn {
    width: 100%;
  }
  .pageContents .bar-filter .aboBox {
    display: block;
    margin-top: 10px;
  }
  .pageContents .bar-filter .aboBox .btn,
  .pageContents .bar-filter .aboBox a.btn {
    width: 100%;
    margin-bottom: 5px;
  }
  .tableActions {
    margin-top: 10px;
  }
  /* ----------------------------------------------------------
	  dashboard
  -----------------------------------------------------------*/
  .dashboard {
    background: #EEE;
    margin-top: -10px;
  }
  .dashboard .dashboard_row {
    *zoom: 1;
    margin: 0 auto;
    padding: 15px;
    width: 100%;
  }
  .dashboard .dashboard_row:before,
  .dashboard .dashboard_row:after {
    display: table;
    content: "";
    line-height: 0;
  }
  .dashboard .dashboard_row:after {
    clear: both;
  }
  .dashboard .dashboard_row .dashboard_col-1,
  .dashboard .dashboard_row .dashboard_col-2 {
    float: none;
    width: auto;
    margin-right: 0;
  }
  .dashboard .flake {
    padding: 20px;
  }
  .dashboard .flake a.btn:not(.btn-icon),
  .dashboard .flake .btn:not(.btn-icon) {
    display: block;
    width: 100%;
    margin-bottom: 5px;
  }
  .dashboard .flake a.btn:not(.btn-icon):last-child,
  .dashboard .flake .btn:not(.btn-icon):last-child {
    margin-bottom: 0;
  }
  .dashboard .flake .flake_icon {
    font-size: 20px;
    left: 20px;
    top: 24px;
  }
  .dashboard .flake .hl-flake {
    font-size: 25px;
    padding-left: 30px;
  }
  .dashboard .flake .item-news .date {
    position: static;
  }
  /* ----------------------------------------------------------
	  responsive tables (via JS)
  -----------------------------------------------------------*/
  .tableResponsified {
    border: 1px solid #DDD;
    margin-bottom: 20px;
  }
  .tableResponsified .tableRow {
    padding: 5px;
    border-bottom: 1px solid #DDD;
  }
  .tableResponsified .tableRow:last-child {
    border-bottom: 0;
  }
  .tableResponsified .tableRow .tableRow-cell-label {
    display: inline-block;
    margin-right: 10px;
    font-weight: 700;
  }
  .tableResponsified .tableRow .tableRow-cell-val {
    display: inline-block;
  }
  /* ----------------------------------------------------------
	  breadcrump
  -----------------------------------------------------------*/
  .breadcrump {
    margin-bottom: 10px;
  }
  /* ----------------------------------------------------------
	  forms
  -----------------------------------------------------------*/
  .inputHelp {
    padding-left: 0 !important;
  }
}
